import React, {useCallback, useEffect, useState} from 'react';
import {Col, Container, Row} from 'reactstrap';
import Pagination from 'react-js-pagination';
import queryString from 'query-string';
import Breadcrumb from '../../components/Breadcrumb';
import {settings} from '../../config/settings';
import Base from '../../components/Base';
import {ContentItem} from '../../components/Contents';
import {getTypeBySlug, getTypeTitle} from '../../components/Contents/Enum/types';
import {getContents} from '../../services/endpoints/contents/contents';
import {hasProperty} from '../../utils/functions';
import FreeSearchBar from './_partials/SearchBar';
import SearchAndCategoriesFilter from './_partials/SearchAndCategoriesFilter';
import {getEndpoints} from '../../services/endpoints/getEndpoints';
import FilterBar from './_partials/FilterBar';
import {
  getFilterCategory,
  getKeys,
  getParamsCategory,
  getTitle,
  getType,
} from '../../components/Contents/Enum/categories';

const ContentList = ({match, location, history}) => {
  const {type} = match.params;
  const params = queryString.parse(location.search);
  const query = hasProperty(params, 'query') ? params.query : '';
  const typeFilter = hasProperty(params, 'typeFilter') ? params.typeFilter : '';
  const byWhiteList = getKeys();
  const by = hasProperty(params, 'by') ? params.by : '';
  const [title, setTitle] = useState(getTypeTitle(type));
  const [headerExtra, setHeaderExtra] = useState({});
  const [pageNotFound, setPageNotFound] = useState(false);
  const getTypeContent = () => {
    if (hasProperty(params, 'filter') && type) {
      return `${getTypeBySlug(type)},${params.filter}`;
    }
    if (hasProperty(params, 'filter')) {
      return params.filter;
    }
    if (type) {
      return getTypeBySlug(type);
    }

    return '';
  };

  const typeContent = getTypeContent();
  const {perPage} = settings;
  const [totalItens, setTotalItens] = useState(0);
  const [contents, setContents] = useState([]);
  const [loading, setLoading] = useState(true);
  const currentPage = hasProperty(params, 'page') ? parseInt(params.page, 10) : 1;

  const loadContents = useCallback(async () => {
    try {
      let urlEndpoint = '/contents';
      const extraTitle = {};
      const parameters = {
        perPage,
        page: currentPage,
      };

      if (query) {
        Object.assign(parameters, {
          title: query,
        });
      }

      if (getKeys().includes(by)) {
        Object.assign(parameters, {
          [`exists[${getType(by)}]`]: true,
        });
        try {
          const res = await getEndpoints(atob(typeFilter));
          const myData = res.data;
          const dataParams = getParamsCategory(by);

          const hasImage = () => {
            if (dataParams.image && myData[dataParams.image]) {
              return myData[dataParams.image].url;
            }
            return null;
          };

          Object.assign(extraTitle, {
            title: myData[dataParams.title] || null,
            description: myData[dataParams.description] || null,
            image: hasImage(dataParams.image),
          });

          Object.assign(parameters, {
            [getFilterCategory(by)]: myData.id,
          });
        } catch (e) {
          console.error(`loadContents.${by}`, e);
          setPageNotFound(true);
        }
      }

      switch (typeContent) {
        case 'Free':
          Object.assign(parameters, {
            free: true,
          });
          break;
        case 'Saved':
          urlEndpoint = '/current_customer_favorite_contents';
          break;
        case 'MyCourses':
          urlEndpoint = '/current_customer_contents';
          break;
        default:
          Object.assign(parameters, {
            resourceType: typeContent,
          });
      }

      const response = await getContents(parameters, urlEndpoint);
      const {data} = response;

      if (typeFilter) {
        setTitle(extraTitle.title);
        setHeaderExtra(extraTitle);
      }
      setTotalItens(data['hydra:totalItems'] || 0);
      setContents(data['hydra:member'] || []);

      setLoading(false);
    } catch (e) {
      console.error('ContentList.getContents', e);
    }
  }, [currentPage, perPage, by, typeFilter, query, typeContent]);

  const handlePageChange = (pageNumber) => {
    history.push({
      search: queryString.stringify({
        page: pageNumber,
      }),
    });
  };

  useEffect(() => {
    loadContents();
    return () => {
      setLoading(true);
      setPageNotFound(false);
    };
  }, [currentPage, loadContents, by, typeFilter]);

  const hasFilterBar = () => {
    const blackList = ['Free', 'Saved', 'MyCourses'];

    return !blackList.includes(typeContent);
  };

  const hasSearchBar = () => {
    return typeContent === 'Free';
  };

  const hasSearchAndCategoriesFilter = () => {
    const whiteList = ['Saved', 'MyCourses'];
    return whiteList.includes(typeContent);
  };

  const Title = () => {
    if (byWhiteList.includes(by)) {
      if (typeFilter) {
        return (
          <Row className="mb-5">
            <Col xs={12}>
              <h1 style={{marginBottom: headerExtra.description ? 30 : 0}}>{headerExtra.title}</h1>
            </Col>
            {!!headerExtra.description && (
              <Col xs={12} md={headerExtra.image ? 10 : 11}>
                <div dangerouslySetInnerHTML={{__html: headerExtra.description}} />
              </Col>
            )}
            {!!headerExtra.image && !!headerExtra.description && (
              <Col xs={3} md={2}>
                <img
                  className="img-fluid d-none d-md-block"
                  src={headerExtra.image}
                  alt={headerExtra.title}
                />
              </Col>
            )}
          </Row>
        );
      }
      return <h1>{getTitle(by)}</h1>;
    }
    if (type === 'Free') {
      return <h1>Cursos Gratuitos</h1>;
    }
    if (!type) {
      return <h1>Cursos</h1>;
    }
    return <h1>{title}</h1>;
  };

  if ((type && !title) || pageNotFound) {
    history.push('/404');
  }

  if (loading) {
    return null;
  }

  const getBreadcrumb = () => {
    const breads = [];
    breads.push({title: 'Cursos', url: title || byWhiteList.includes(by) ? '/cursos' : null});

    if (byWhiteList.includes(by) && typeFilter) {
      breads.push({
        title: getTitle(by),
        url: `/cursos?by=${by}`,
      });
      breads.push({title});
    } else if (byWhiteList.includes(by)) {
      breads.push({title: getTitle(by)});
    } else {
      breads.push({title});
    }
    return breads;
  };

  return (
    <Base slug="list-all-pages" hideBreadcrumb>
      <Container className="pb-5">
        <Breadcrumb contents={getBreadcrumb()} />

        <Title />

        {hasSearchBar() && <FreeSearchBar />}
        {hasSearchAndCategoriesFilter() && <SearchAndCategoriesFilter />}
        {hasFilterBar() && <FilterBar by={by} />}

        <Row>
          {contents.length ? (
            contents.map((content) => (
              <Col key={content['@id']} xs={12} sm={6} md={4} lg={3}>
                <ContentItem
                  content={content}
                  showFavoriteRemove={typeContent === 'Saved'}
                  settings={{
                    breadcrumb: {
                      typeFilterTitle: title,
                    },
                    title: {
                      truncate: 3,
                    },
                    description: {
                      truncate: 3,
                    },
                  }}
                />
              </Col>
            ))
          ) : (
            <Col xs={12}>
              <p>Nenhum registro encontrado até o momento...</p>
            </Col>
          )}
        </Row>

        {contents.length > 0 && totalItens > perPage && (
          <Pagination
            {...settings.pagination}
            activePage={currentPage}
            itemsCountPerPage={perPage}
            totalItemsCount={totalItens}
            onChange={handlePageChange}
          />
        )}
      </Container>
    </Base>
  );
};

export default ContentList;
