import api from '../../api';

export const getNotifications = (params = {}) => {
  return api.get('/notifications', {
    params: {
      ...params,
    },
  });
};

export const getNotificationsId = (id, params = {}) => {
  return api.get(`/notifications/${id}`, {
    params: {
      ...params,
    },
  });
};
export const seeNotification = (id, params = {}) => {
  return api.post(`/notifications/${id}/see`, {
    params: {
      ...params,
    },
  });
};

export const sendNotifications = (data, params = {}) => {
  return api.post('/send_notifications', data, {
    params: {
      ...params,
    },
  });
};

export const unseenNotifications = (params = {}) => {
  return api.get('/unseen_notifications', {
    params: {
      ...params,
    },
  });
};
