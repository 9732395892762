import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Favorite} from './Favorite';
import {RatingsShow} from './RatingsShow';
import {Ratings} from './Ratings';
import {getToken} from '../../../utils/auth';
import ShareButton from './ShareButton';

const Collections = ({content, hasShare, enabledRatings, watched, showWatched}) => {
  return (
    <Row>
      {enabledRatings && content.hasAccess ? (
        <Ratings content={content} />
      ) : (
        <RatingsShow ratings={content.rating || 5} />
      )}
      {showWatched && watched && <Watched>Assistido</Watched>}
      {hasShare && <ShareButton />}
      {getToken() && (
        <Favorite
          favorite={
            !!content.userCollections.filter(
              (collection) => collection['@type'] === 'UserCollectionFavorite',
            )[0]
          }
          content={content['@id']}
        />
      )}
    </Row>
  );
};

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const Watched = styled.div`
  background: #00985f;
  color: #fff;
  font-size: 9px;
  text-transform: uppercase;
  padding: 0 15px;
  margin: 0 5px;
  border-radius: 3px;
`;

Collections.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  hasShare: PropTypes.bool,
  enabledRatings: PropTypes.bool,
  watched: PropTypes.bool,
  showWatched: PropTypes.bool,
};
export default Collections;
