import {redirectToLogin, refreshLogin} from './auth';

function checkDetails(detail) {
  switch (detail) {
    case 'Authentication Required: Expired Cognito Token':
      refreshLogin();
      break;
    case 'Authentication Required: Login Limit Exceed':
      refreshLogin();
      break;
    case 'Authenticated user is not a customer':
    case 'Access Denied.':
      redirectToLogin(
        'Vm9jw6ogbsOjbyBwb3NzdWkgcGVybWlzc8OjbyBwYXJhIGFjZXNzYXIgZXN0ZSBjb250ZcO6ZG8',
      );
      break;
    case 'Authentication Required: Full authentication is required to access this resource.':
    case 'Authentication Required: Invalid Cognito Token':
    default:
      redirectToLogin();
  }
}

export {checkDetails};
