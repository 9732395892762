import React from 'react';
import styled from 'styled-components';

const ShareButton = () => {
  return <Share className="far fa-share-alt" />;
};

const Share = styled.div`
  position: relative;
  font-size: 17px;
  color: #002c63;
  cursor: pointer;
  margin: 0 10px;
  display: flex;
  flex: auto;
  flex-direction: row-reverse;
`;

export default ShareButton;
