import React from 'react';
import PropTypes from 'prop-types';
import {Col, Container, Row} from 'reactstrap';
import {Box, Description, Icon, Info, Item, MetaInfo, Number, Title} from './styled';

const BlockHubEducacao = ({content, block}) => {
  return (
    <Box>
      <Container>
        <Row className="align-items-center">
          <Col xs={12} sm={12} lg={4} xl={5} className="mb-3 mb-lg-0">
            <Title>{block.title}</Title>
            <Description dangerouslySetInnerHTML={{__html: block.description}} />
          </Col>
          <Col xs={12} sm={12} lg={8} xl={7}>
            <div>
              <Row>
                <Col xs={12} sm={4}>
                  <Item>
                    <MetaInfo>
                      <Icon className="ico-user-circle" />
                      <Number>{content.totalUsers || 0}</Number>
                      <Info>Usuários Cadastrados</Info>
                    </MetaInfo>
                  </Item>
                </Col>
                <Col xs={12} sm={4}>
                  <Item>
                    <MetaInfo>
                      <Icon className="ico-notebook" />
                      <Number>{content.totalContents || 0}</Number>
                      <Info>Conteúdos</Info>
                    </MetaInfo>
                  </Item>
                </Col>
                <Col xs={12} sm={4}>
                  <Item>
                    <MetaInfo>
                      <Icon className="ico-theme" />
                      <Number>{content.totalThemes || 0}</Number>
                      <Info>Temas</Info>
                    </MetaInfo>
                  </Item>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </Box>
  );
};

BlockHubEducacao.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  block: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
export default BlockHubEducacao;
