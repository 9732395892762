function calculateIdentity(str, weight) {
  let sum = 0;

  for (let i = str.length - 1, digit; i >= 0; i -= 1) {
    digit = parseInt(str.substring(i, i + 1), 10);
    sum += digit * weight[weight.length - str.length + i];
  }

  sum = 11 - (sum % 11);
  return sum > 9 ? 0 : sum;
}

function cpfValidate(str) {
  const weight = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2];
  const cpf = str.replace(/\D/g, '');

  if (cpf === null || cpf.length !== 11) return false;

  const digit1 = calculateIdentity(cpf.substring(0, 9), weight);
  const digit2 = calculateIdentity(cpf.substring(0, 9) + digit1, weight);

  return cpf === cpf.substring(0, 9) + digit1.toString() + digit2.toString();
}

function cnpjValidate(str) {
  const weight = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  const cnpj = str.replace(/\D/g, '');

  if (cnpj === null || cnpj.length !== 14) return false;

  const digit1 = calculateIdentity(cnpj.substring(0, 12), weight);
  const digit2 = calculateIdentity(cnpj.substring(0, 12) + digit1, weight);

  return cnpj === cnpj.substring(0, 12) + digit1.toString() + digit2.toString();
}

const invalidCpfList = [
  '00000000000',
  '11111111111',
  '22222222222',
  '33333333333',
  '44444444444',
  '55555555555',
  '66666666666',
  '77777777777',
  '88888888888',
  '99999999999',
];

function isCPF(value = '') {
  if (value) {
    if (invalidCpfList.includes(value.replace(/\D/g, ''))) {
      return false;
    }
    return !!cpfValidate(value);
  }
  return true;
}

function isCNPJ(value = '') {
  if (value === undefined || value === null || !value.length) {
    return true;
  }
  return !!cnpjValidate(value);
}

export {isCPF, isCNPJ};
