import React, {useCallback, useEffect, useState} from 'react';
import {Col, Container, Row} from 'reactstrap';
import Pagination from 'react-js-pagination';
import queryString from 'query-string';
import Truncate from 'react-truncate';
import Base from '../../components/Base';
import {settings} from '../../config/settings';
import {hasProperty} from '../../utils/functions';
import {getTools} from '../../services/endpoints/tools/tools';
import {Description, Item, LinkImage, MetaInfo, Title} from './styled';
import {Image} from '../../components/Contents/_partials/styled';
import noImage from '../../assets/images/no-course.jpg';

const Tools = ({history, location}) => {
  const [contents, setContents] = useState([]);
  const params = queryString.parse(location.search);
  const {perPage} = settings;
  const [loading, setLoading] = useState(true);
  const [totalItens, setTotalItens] = useState(0);
  const currentPage = hasProperty(params, 'page') ? parseInt(params.page, 10) : 1;

  const loadContents = useCallback(async () => {
    try {
      const response = await getTools({
        perPage,
        page: currentPage,
      });

      const {data} = response;
      setTotalItens(data['hydra:totalItems'] || 0);
      setContents(data['hydra:member'] || []);

      setLoading(false);
    } catch (e) {
      console.error('Tools.loadContents.getTools', e);
    }
  }, [currentPage, perPage]);

  const handlePageChange = (pageNumber) => {
    history.push({
      search: queryString.stringify({
        page: pageNumber,
      }),
    });
  };

  useEffect(() => {
    loadContents();
    return () => {
      setLoading(true);
    };
  }, [currentPage, loadContents]);

  if (loading) {
    return null;
  }

  const BuildImage = ({content}) => {
    const {thumb, title} = content;

    if (thumb) {
      return <Image src={thumb.url} alt={title} />;
    }
    return <Image src={noImage} alt={title} />;
  };

  return (
    <Base slug={['vitrine-de-ferramentas', 'list-all-pages']}>
      <Container>
        {!!contents.length && (
          <Row>
            {contents.map((content) => (
              <Col key={content['@id']} xs={12} sm={6} md={4} lg={3}>
                <Item>
                  <LinkImage href={content.link || '#'} target={content.link ? '_blank' : '_self'}>
                    <BuildImage content={content} />
                  </LinkImage>
                  <MetaInfo>
                    <Title
                      href={content.link || '#'}
                      target={content.link ? '_blank' : '_self'}
                      height={3}>
                      <Truncate lines={3}>{content.title}</Truncate>
                    </Title>
                    <Description height={3}>
                      <Truncate lines={3}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: content.description,
                          }}
                        />
                      </Truncate>
                    </Description>
                  </MetaInfo>
                </Item>
              </Col>
            ))}
          </Row>
        )}

        {contents.length > 0 && totalItens > perPage && (
          <Pagination
            {...settings.pagination}
            activePage={currentPage}
            itemsCountPerPage={perPage}
            totalItemsCount={totalItens}
            onChange={handlePageChange}
          />
        )}
      </Container>
    </Base>
  );
};

export default Tools;
