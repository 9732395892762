import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Link} from '../../Elements';
import {nameFormat} from '../../../utils/manipulateString';

const ProfileMenu = ({user}) => {
  return (
    <li>
      <Link to="/perfil">
        <I className="icon-user fal fa-user-circle" title={nameFormat(user.name)} />
      </Link>
      <ul style={{right: 0}}>
        <li>
          <Link to="/perfil">Perfil</Link>
        </li>
        {/* <li>
          <Link to="/certificados">Certificados</Link>
        </li> */}
        <li>
          <Link to="/cursos/salvos">Salvos</Link>
        </li>
        {/* <li>
          <Link to="/loja">Loja</Link>
        </li>
        <li>
          <Link to="/cursos/meus-cursos">Conteúdos Comprados</Link>
        </li> */}
        <li>
          <Link to="/vitrine-de-ferramentas">Vitrine de ferramentas</Link>
        </li>
        <li>
          <Link to="/sair">Sair</Link>
        </li>
      </ul>
    </li>
  );
};

const I = styled.i`
  font-size: 20px !important;
`;

ProfileMenu.proptTypes = {
  user: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default ProfileMenu;
