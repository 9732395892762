import React from 'react';
import PropTypes from 'prop-types';
import {RatingStarsInner, RatingStarsOuter} from './styled';

const RatingsShow = ({ratings}) => {
  const total = 5;
  const rounded = `${Math.round((ratings / total) * 100)}%`;

  return (
    <RatingStarsOuter>
      <RatingStarsInner stars={rounded} />
    </RatingStarsOuter>
  );
};

RatingsShow.propTypes = {
  ratings: PropTypes.number.isRequired,
};

export {RatingsShow};
