import api from '../api';

const getEndpoints = (endpoints, params = {}) =>
  api.get(endpoints.replace('/v1/', '/'), {
    params: {
      ...params,
    },
  });

export {getEndpoints};
