import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Col, Container, Row} from 'reactstrap';
import ComponentFactory from '../ComponentFactory';
import {loadPage} from '../../utils/loadPage';
import Logo from '../../assets/images/logo.png';
import Banner from '../Banner';
import {settings} from '../../config/settings';
import {Copyright, Header, HeaderContent} from './styled';
import {SearchBlock} from '../Search';
import {hasProperty} from '../../utils/functions';
import Breadcrumb from '../Breadcrumb';
import MenuFooterAutomatic from '../MenuFooterAutomatic';

const Base = ({children, slug, hideBreadcrumb = false}) => {
  const [page, setPage] = useState({});
  const [containers, setContainers] = useState([]);
  const mountedRef = useRef(true);

  useEffect(() => {
    async function init() {
      if (!mountedRef.current) return null;
      try {
        if (typeof slug === 'string') {
          const contents = await loadPage(slug);
          setPage(contents.page);
          setContainers(contents.blocks);
        } else {
          let defaulPage = false;
          await slug.forEach(async (sl) => {
            const contents = await loadPage(sl);
            if (!defaulPage && contents.page.slug !== 'list-all-pages') {
              defaulPage = true;
              setPage(contents.page);
            }
            setContainers((oldBlocks) => [...oldBlocks, ...contents.blocks]);
          });
        }
      } catch (err) {
        console.error('Base.init.error', err);
      }
    }
    init();

    return () => {
      mountedRef.current = false;
    };
  }, [slug]);
  return (
    <>
      <Header>
        <div className="container-fluid">
          <HeaderContent>
            <Link to="/" className="logo">
              <img className="img-fluid" src={Logo} alt={settings.titleSite} />
            </Link>

            <SearchBlock />

            {containers
              .filter(({container}) => container === 'header')
              .map((block, index) => (
                <ComponentFactory
                  key={`header-${index}`}
                  componentName={block.componentName}
                  content={block.content}
                  block={block.block}
                />
              ))}
          </HeaderContent>
        </div>
      </Header>

      {page && page.banner && <Banner content={page.banner} />}

      <section>
        {containers
          .filter(({container}) => container === 'main')
          .map((block, index) => (
            <ComponentFactory
              key={`main-${index}`}
              componentName={block.componentName}
              content={block.content}
              block={block.block}
            />
          ))}
      </section>

      <main
        className={`page-content${!page.hideTitle || hasProperty(page, 'content') ? ' pt-3' : ''}`}
        style={{
          backgroundColor: '#f7f7f7',
        }}>
        {Object.keys(page).length > 0 && (
          <Container>
            <div className="content-show">
              {!hideBreadcrumb && (
                <Breadcrumb contents={[{title: 'Home', url: '/'}, {title: page.title}]} />
              )}

              {!page.hideTitle && <h1>{page.title}</h1>}

              {containers
                .filter(({container}) => container === 'innerContent')
                .map((block, index) => (
                  <ComponentFactory
                    key={`innerContent-${index}`}
                    componentName={block.componentName}
                    content={block.content}
                    block={block.block}
                  />
                ))}

              {hasProperty(page, 'content') === true && (
                <div
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html: page.content,
                  }}
                />
              )}
            </div>
          </Container>
        )}

        {children && <div className="content">{children}</div>}
      </main>

      <section>
        {containers
          .filter(({container}) => container === 'content')
          .map((block, index) => (
            <ComponentFactory
              key={`content-${index}`}
              componentName={block.componentName}
              content={block.content}
              block={block.block}
            />
          ))}
      </section>

      <footer className="page-footer">
        <div className="container">
          <div className="page-footer-items">
            <Row>
              <Col xs={12} lg={6}>
                {containers
                  .filter(({container}) => container === 'footer')
                  .map((block, index) => (
                    <ComponentFactory
                      key={`footer-${index}`}
                      componentName={block.componentName}
                      content={block.content}
                      block={block.block}
                    />
                  ))}
              </Col>
              <Col xs={12} lg={6}>
                <MenuFooterAutomatic />
              </Col>
            </Row>
          </div>

          <Copyright>{settings.copyright}</Copyright>
        </div>
      </footer>
    </>
  );
};

Base.propTypes = {
  children: PropTypes.any,
  slug: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  hideBreadcrumb: PropTypes.bool,
};

export default Base;
