import React from 'react';
import {MenuFooter} from './styled';
import ObjectivesMenu from '../Menu/_partials/ObjectivesMenu';
import ThemesMenu from '../Menu/_partials/ThemesMenu';
import PartnersMenu from '../Menu/_partials/PartnersMenu';

const MenuFooterAutomatic = () => {
  return (
    <MenuFooter>
      <ThemesMenu />
      <ObjectivesMenu />
      <PartnersMenu />
    </MenuFooter>
  );
};

export default MenuFooterAutomatic;
