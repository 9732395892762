import React from 'react';
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import {GuardedRoute, GuardProvider} from 'react-router-guards';
import {getToken, redirectToLogin} from './utils/auth';
import {authenticator} from './services/auth';
import Pages from './pages';
import NotFound from './pages/NotFound';
import ContentList from './pages/Contents/ContentList';
import ContentShow from './pages/Contents/ContentShow';
import ContentInit from './pages/Contents/ContentInit';
import ContentExam from './pages/Contents/ContentExam';
import Profile from './pages/Profile';
import Tools from './pages/Tools';
import Search from './pages/Search/Search';
import PageErrors from './pages/Errors';
import Partners from './pages/Partners/Partners';
import PartnersList from './pages/Partners';
import Certificates from './pages/Certificates';

const testUrl = async (to, from, next) => {
  const blackList = [
    '/perfil',
    '/certificados',
    '/loja',
    '/cursos/meus-cursos',
    '/vitrine-de-ferramentas',
    '/sair',
  ];

  if (getToken() || blackList.includes(to.location.pathname)) {
    try {
      await authenticator();
      next();
    } catch (e) {
      console.error('Erro ao autenticar o usuário');
    }
  }

  next();
};

const Routes = () => (
  <Router>
    <GuardProvider guards={[testUrl]}>
      <Switch>
        <GuardedRoute path="/" exact component={Pages} />
        <GuardedRoute path="/404" component={NotFound} />
        <GuardedRoute path="/erros" component={PageErrors} />

        <GuardedRoute path="/busca" exact component={Search} />

        <GuardedRoute path="/parceiros" exact component={Partners} />
        <GuardedRoute path="/parceiros/:slug" exact component={PartnersList} />

        <GuardedRoute path="/cursos" exact component={ContentList} />
        <GuardedRoute path="/cursos/:type" exact component={ContentList} />
        <GuardedRoute path="/cursos/:type/:slug" exact component={ContentShow} />

        <GuardedRoute path="/cursos/:type/:slug/iniciar" exact component={ContentInit} />
        <GuardedRoute path="/avaliacao/:id/:pathCourse" exact component={ContentExam} />

        {/* init required login */}
        <GuardedRoute path="/vitrine-de-ferramentas" exact component={Tools} />
        <GuardedRoute path="/perfil" exact component={Profile} />
        <GuardedRoute path="/certificados" exact component={Certificates} />
        <GuardedRoute path="/loja" exact component={() => redirectToLogin()} />
        <GuardedRoute path="/sair" exact component={() => redirectToLogin()} />
        {/* end required login */}

        <GuardedRoute path="/:slug" exact component={Pages} />
        <GuardedRoute path="*" component={NotFound} />
      </Switch>
    </GuardProvider>
  </Router>
);

export default Routes;
