import React, {useCallback, useEffect, useState} from 'react';
import {Col, Container, Row} from 'reactstrap';
import * as Yup from 'yup';
import {useAlert} from 'react-alert';
import Base from '../../components/Base';
import Breadcrumb from '../../components/Breadcrumb';
import {
  Box,
  DashboardIcon,
  DashboardItem,
  DashboardTitle,
  DashboardValue,
  FormStyled,
} from './styled';
import AvatarUpload from '../../components/AvatarUpload';
import {getCurrentUser, setCurrentUser} from '../../utils/auth';
import GenerateForm from '../../components/GenerateForm';
import {isCPF} from '../../utils/yup/identityValidate';
import {
  changePasswordUser,
  getUserStatistics,
  updateUser,
} from '../../services/endpoints/general/currentUser';
import {states} from './jsons/states';

const Profile = () => {
  const alert = useAlert();
  const [loading, setLoading] = useState(true);
  const {profile} = getCurrentUser();
  const [statistics, setStatistics] = useState(true);

  Yup.addMethod(Yup.string, 'cpf', (message) => {
    return Yup.mixed().test('cpf', message, (value) => isCPF(value));
  });

  const groupFields = [
    {
      name: 'IDENTIFICAÇÃO',
      fields: [
        {
          label: 'Nome',
          hasPlaceholder: true,
          name: 'name',
          value: profile.name,
          required: true,
          validations: {
            name: Yup.string().required('Campo obrigatório'),
          },
          columns: {
            xs: 12,
          },
        },
        {
          label: 'Celular',
          hasPlaceholder: true,
          name: 'phoneNumber',
          value: profile.phoneNumber || '',
          required: true,
          validations: {
            phoneNumber: Yup.string().required('Campo obrigatório'),
          },
          mask: '+99 (99) 99999-9999',
          maskType: 'phone',
          columns: {
            xs: 6,
          },
        },
        {
          label: 'CPF',
          hasPlaceholder: true,
          name: 'identity',
          value: profile.identity || '',
          required: true,
          validations: {
            identity: Yup.string().required('Campo obrigatório').cpf('Cpf Inválido'),
          },
          mask: '999.999.999-99',
          columns: {
            xs: 6,
          },
        },
      ],
    },
    {
      name: 'ENDEREÇO',
      fields: [
        {
          label: 'CEP',
          hasPlaceholder: true,
          name: 'zipCode',
          value: profile.zipCode || '',
          required: true,
          validations: {
            zipCode: Yup.string().required('Campo obrigatório'),
          },
          mask: '99999-999',
          columns: {
            xs: 4,
          },
        },
        {
          label: 'Rua',
          hasPlaceholder: true,
          name: 'street',
          value: profile.street || '',
          required: true,
          validations: {
            street: Yup.string().required('Campo obrigatório'),
          },
          mask: '',
          columns: {
            xs: 8,
          },
        },
        {
          label: 'Número',
          hasPlaceholder: true,
          name: 'houseNumber',
          value: profile.houseNumber || '',
          required: true,
          validations: {
            houseNumber: Yup.string().required('Campo obrigatório'),
          },
          mask: '',
          columns: {
            xs: 5,
          },
        },
        {
          label: 'Complemento',
          hasPlaceholder: true,
          name: 'complement',
          value: profile.complement || '',
          required: true,
          validations: {
            complement: Yup.string().required('Campo obrigatório'),
          },
          mask: '',
          columns: {
            xs: 7,
          },
        },
        {
          label: 'Bairro',
          hasPlaceholder: true,
          name: 'neighborhood',
          value: profile.neighborhood || '',
          required: true,
          validations: {
            neighborhood: Yup.string().required('Campo obrigatório'),
          },
          mask: '',
          columns: {
            xs: 6,
          },
        },
        {
          label: 'Cidade',
          hasPlaceholder: true,
          name: 'city',
          value: profile.city || '',
          required: true,
          validations: {
            city: Yup.string().required('Campo obrigatório'),
          },
          mask: '',
          columns: {
            xs: 6,
          },
        },
        {
          label: 'Estado',
          hasPlaceholder: true,
          name: 'state',
          value: profile.state || '',
          required: true,
          validations: {
            state: Yup.string().required('Campo obrigatório'),
          },
          mask: '',
          type: 'select',
          options: states,
          columns: {
            xs: 6,
          },
        },
      ],
    },
    {
      name: 'SEGURANÇA',
      fields: [
        {
          label: 'Senha',
          hasPlaceholder: false,
          type: 'password',
          name: 'plainPassword',
          value: '',
          required: true,
          validations: {
            plainPassword: Yup.string()
              .min(8, 'Senha com no mínimo 8 caracteres')
              .max(24, 'Senha com no máximo 24 caracteres')
              .matches(/[a-z]/, 'Pelo menos um caractere minúsculo')
              .matches(/[A-Z]/, 'Pelo menos um caractere maiúsculo')
              .matches(/[0-9]/, 'Pelo menos um número')
              .matches(/[@$!%*#?&.<>]/, 'Pelo menos um caractere especial ex: @,$,!,%...'),
          },
          mask: '',
          columns: {
            xs: 6,
          },
        },
        {
          label: 'Confirmar Senha',
          hasPlaceholder: false,
          type: 'password',
          name: 'confirmPassword',
          value: '',
          required: true,
          validations: {
            confirmPassword: Yup.string().when('plainPassword', {
              is: (val) => val && val.length >= 8,
              then: Yup.string()
                .oneOf([Yup.ref('plainPassword')], 'As senhas não são iguais')
                .required('Campo obrigatório'),
            }),
          },
          mask: '',
          columns: {
            xs: 6,
          },
        },
      ],
    },
  ];

  const onSubmit = async (values, actions) => {
    const data = {
      name: values.name,
      phoneNumber: values.phoneNumber.replace(/\D/g, ''),
      identity: values.identity,
      zipCode: values.zipCode,
      street: values.street,
      houseNumber: values.houseNumber,
      complement: values.complement,
      neighborhood: values.neighborhood,
      city: values.city,
      state: values.state,
    };

    try {
      const response = await updateUser(data);
      setCurrentUser({...getCurrentUser(), profile: response.data});

      // save password if exits
      if (values.plainPassword.length >= 8) {
        await changePasswordUser({
          plainPassword: values.plainPassword,
        });
      }
      alert.show('Dados salvos com sucesso!');
      actions.setSubmitting(false);
    } catch (e) {
      alert.show('Ocorreu um erro ao salvar os dados, tente novamente mais tarde!');
      actions.setSubmitting(false);
      console.error('Profile.onSubmit.updateUser', e);
    }

    actions.setSubmitting(false);
  };

  const loadStatistics = useCallback(async () => {
    const response = await getUserStatistics();
    setStatistics(response.data || {});
    setLoading(false);
  }, []);

  useEffect(() => {
    loadStatistics();
    return () => {
      setLoading(true);
    };
  }, [loadStatistics]);

  if (loading) {
    return null;
  }

  return (
    <Base slug={['list-all-pages']} hideBreadcrumb>
      <Container>
        <Breadcrumb contents={[{title: 'Perfil'}]} />

        <h1>Perfil</h1>

        <Box>
          <Row className="align-items-center">
            <Col xs={12} sm={4} md={7} lg={6}>
              <AvatarUpload user={getCurrentUser()} />

              <Row>
                <Col xs={12} sm={4}>
                  <DashboardItem>
                    <DashboardIcon className="far fa-graduation-cap" />
                    <DashboardTitle>Certificado</DashboardTitle>
                    <DashboardValue>{statistics.certificatesQuantity}</DashboardValue>
                  </DashboardItem>
                </Col>
                <Col xs={12} sm={4}>
                  <DashboardItem>
                    <DashboardIcon className="far fa-eye" />
                    <DashboardTitle>Visualizados</DashboardTitle>
                    <DashboardValue>{statistics.viewedCoursesQuantity}</DashboardValue>
                  </DashboardItem>
                </Col>
                <Col xs={12} sm={4}>
                  <DashboardItem>
                    <DashboardIcon className="far fa-bookmark" />
                    <DashboardTitle>Salvos</DashboardTitle>
                    <DashboardValue>{statistics.favoritedCoursesQuantity}</DashboardValue>
                  </DashboardItem>
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={8} md={5} lg={6}>
              <FormStyled>
                <GenerateForm onSubmit={onSubmit} groupFields={groupFields} />
              </FormStyled>
            </Col>
          </Row>
        </Box>
      </Container>
    </Base>
  );
};

export default Profile;
