import React from 'react';
import PropTypes from 'prop-types';
import {Container} from 'reactstrap';
import Slider from 'react-slick';
import {ContentItem} from '..';
import {Box, Item, NextArrowStyled, PrevArrowStyled, Title} from './BlockContentListStyled';

const BlockContentList = ({content, block}) => {
  const contents = content['hydra:member'];

  const PrevArrow = ({onClick}) => {
    return <PrevArrowStyled className="fal fa-angle-left" onClick={onClick} />;
  };

  const NextArrow = ({onClick}) => {
    return <NextArrowStyled className="fal fa-angle-right" onClick={onClick} />;
  };

  const settings = {
    dots: true,
    infinite: false,
    arrow: true,
    speed: 500,
    draggable: false,
    fade: false,
    autoplay: false,
    autoplaySpeed: 4000,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 577,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box>
      <Container className="mb-5">
        <Title>{block.title}</Title>
        {contents.length ? (
          <Slider {...settings}>
            {contents.map((item) => (
              <Item key={item.id}>
                <ContentItem
                  addClass="no-shadow"
                  content={item}
                  settings={{
                    noHover: true,
                    description: {
                      truncate: 3,
                    },
                  }}
                  playlist={btoa(decodeURI(content['hydra:view']['@id']))}
                />
              </Item>
            ))}
          </Slider>
        ) : (
          <p>Nenhum registro encontrado até o momento...</p>
        )}
      </Container>
    </Box>
  );
};

BlockContentList.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  block: PropTypes.oneOfType([PropTypes.object]),
};
export default BlockContentList;
