import {getPage} from '../services/endpoints/cms/pages';
import {getEndpoints} from '../services/endpoints/getEndpoints';

const loadPage = async (slug) => {
  try {
    const response = await getPage(slug);
    const endPoints = [];

    response.data.blockPages.forEach((block) => {
      endPoints.push(getEndpoints(block.block.endpoint));
    });

    const resultEndPoints = await Promise.all(endPoints);

    const blocks = [];
    response.data.blockPages.forEach((block, index) => {
      const blockContent = resultEndPoints[index].data;
      const componentName = block.block.component ? block.block.component.name : '';
      const {container} = block;
      const content = {
        container: container.name || null,
        componentName,
        content: blockContent,
        block: {
          id: block.block.id,
          title: block.block.title || '',
          description: block.block.description || null,
        },
      };
      blocks.push(content);
    });

    return {blocks, page: response.data};
  } catch (err) {
    console.error('loadPage', err);

    return {blocks: [], page: {}};
  }
};

export {loadPage};
