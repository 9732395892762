import styled from 'styled-components';
import grassdoor from '../../assets/images/socialnetworking/glassdoor-white.png';

export const Box = styled.div``;

export const RedesSociaisItems = styled.div`
  display: flex;
  padding: 30px 0;
  a {
    font-size: 26px;
    line-height: 36px;
    width: 36px;
    height: 36px;
    text-decoration: none;
    margin: 3px;
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    .fa-grassdoor {
      background: url(${grassdoor}) no-repeat center center;
      width: 100%;
      height: 100%;
    }
  }
`;
