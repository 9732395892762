import React from 'react';
import PropTypes from 'prop-types';
import {Col, Container, Row} from 'reactstrap';
import toSlugCase from 'to-slug-case';
import Truncate from 'react-truncate';
import Collections from '../_partials/Collections';
import {
  Box,
  Button,
  Category,
  ContentTitle,
  Description,
  FreeContent,
  Image,
  Item,
  LinkImage,
  MetaInfo,
  Partner,
  Price,
  TagsButtons,
  Title,
} from '../_partials/styled';
import {getType} from '../Enum/types';
import {priceFormat} from '../../../utils/functions';
import {getToken} from '../../../utils/auth';

const ContentLoggedOut = ({content}) => {
  const type = content['@type'];
  const urlType = `/cursos/${toSlugCase(getType(type))}`;
  const urlContent =
    content.price && getToken()
      ? `/carrinho/add/${content.id}`
      : `${process.env.REACT_APP_SIGNIN_URL}?signUp=${btoa(window.location.href)}`;

  return (
    <Container>
      <Row>
        <Col xs={12} sm={8} md={6}>
          <ContentTitle>{content.title}</ContentTitle>
          <Description dangerouslySetInnerHTML={{__html: content.summary}} />
        </Col>
        <Col xs={12} sm={8} md={6}>
          <Box>
            <Item>
              {content.thumb && (
                <LinkImage href={urlContent} type="a" widescreen>
                  {content.free && <FreeContent>Gratuito</FreeContent>}
                  <Image src={content.thumb.url} />
                </LinkImage>
              )}
              <MetaInfo>
                <Collections content={content} />

                <TagsButtons>
                  {getType(type) && <Category to={urlType}>{getType(type)}</Category>}
                  {content.partner && (
                    <Partner to={`/cursos?by=parceiros&typeFilter=${btoa(content.partner['@id'])}`}>
                      {content.partner.name}
                    </Partner>
                  )}
                </TagsButtons>

                {!!content.price && (
                  <Price>
                    {!content.promotionalPrice && <div> de {priceFormat(content.price)}</div>}
                    {priceFormat(content.currentPrice)}
                  </Price>
                )}

                <Title href={urlContent} type="a" height={1}>
                  <Truncate lines={1}>{content.title}</Truncate>
                </Title>

                <Description height={2}>
                  <Truncate lines={2}>
                    <div dangerouslySetInnerHTML={{__html: content.summary}} />
                  </Truncate>
                </Description>

                <Button
                  href={urlContent}
                  type="a"
                  color={content.price ? 'green' : 'primary-light'}>
                  {content.price ? 'Comprar agora' : 'Cadastre-se'}
                </Button>
              </MetaInfo>
            </Item>
          </Box>
        </Col>
      </Row>
    </Container>
  );
};

ContentLoggedOut.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
export {ContentLoggedOut};
