import React from 'react';
import PropTypes from 'prop-types';
import {Container} from 'reactstrap';
import {
  AboutBlockHome as AboutBlockHomeStyled,
  Button,
  Description,
  Embed,
  Iframe,
  Title,
} from './styled';
import {getSrc} from '../../utils/manipulateString';

const AboutBlockHome = ({content}) => {
  const feature = content || {};
  return (
    <AboutBlockHomeStyled>
      <Container>
        <div className="row flex-row-reverse">
          <div className="col-12 col-md-5 col-lg-6 mb-3 d-flex flex-column justify-content-center">
            <Title>{feature.title}</Title>
            <Description dangerouslySetInnerHTML={{__html: feature.description}} />
            {!!feature.link && (
              <Button color="secondary" to={feature.link}>
                Saiba mais
              </Button>
            )}
          </div>
          <div className="col-12 col-md-7 col-lg-6 mb-3">
            <Embed>
              <Iframe src={getSrc(feature.embeddedFile)} />
            </Embed>
          </div>
        </div>
      </Container>
    </AboutBlockHomeStyled>
  );
};

AboutBlockHome.propTypes = {
  content: PropTypes.object.isRequired,
};

export default AboutBlockHome;
