import Cookie from 'js-cookie';
import {asyncLocalStorage} from './asyncLocalStorage';

function getToken() {
  return Cookie.get(process.env.REACT_APP_TOKEN);
}
function deleteToken() {
  return Cookie.remove(process.env.REACT_APP_TOKEN);
}

function getCurrentUser() {
  if (getToken()) {
    return JSON.parse(localStorage.getItem(`${process.env.REACT_APP_PREFIX}.user`));
  }
  return {
    user: {},
    profile: {},
  };
}

async function getAsyncCurrentUser() {
  return asyncLocalStorage.getItem(`${process.env.REACT_APP_PREFIX}.user`);
}

function setCurrentUser(data) {
  localStorage.setItem(`${process.env.REACT_APP_PREFIX}.user`, JSON.stringify(data));
}

function setAsyncCurrentUser(data) {
  return asyncLocalStorage.setItem(`${process.env.REACT_APP_PREFIX}.user`, JSON.stringify(data));
}

const goToWebsite = process.env.REACT_APP_WEBSITE_URL;
const getLogoutPage = `${process.env.REACT_APP_SIGNIN_URL}?logout=${btoa(
  document.location.origin,
)}`;
const getLoginPage = `${process.env.REACT_APP_SIGNIN_URL}?login=${btoa(document.location.href)}`;

function redirectToLogin(msg = '') {
  localStorage.clear();
  deleteToken();
  window.location = `${getLogoutPage}${msg ? `&msg=${msg}` : ''}`;
  return null;
}

function refreshLogin() {
  localStorage.clear();
  window.location = `${getLoginPage}`;
}

export {
  getToken,
  getCurrentUser,
  getAsyncCurrentUser,
  setCurrentUser,
  setAsyncCurrentUser,
  redirectToLogin,
  refreshLogin,
  getLoginPage,
  getLogoutPage,
  goToWebsite,
};
