import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import {Container} from 'reactstrap';
import {Image, NextArrowStyled, Partner, PrevArrowStyled, SliderStyled, Title} from './styled';

const BlockPartners = ({content}) => {
  const partners = content['hydra:member'] || [];

  const PrevArrow = ({onClick}) => {
    return <PrevArrowStyled className="fal fa-angle-left" onClick={onClick} />;
  };

  const NextArrow = ({onClick}) => {
    return <NextArrowStyled className="fal fa-angle-right" onClick={onClick} />;
  };

  return (
    <Container>
      <Title>Parceiros</Title>

      <SliderStyled>
        <Slider
          dots={false}
          infinite={partners.length > 1}
          arrows
          speed={500}
          draggable={false}
          nextArrow={<NextArrow />}
          prevArrow={<PrevArrow />}
          autoplay
          autoplaySpeed={4000}
          slidesToShow={6}
          slidesToScroll={6}
          responsive={[
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 5,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
          ]}>
          {partners.map((partner) => (
            <Partner key={partner.id}>{partner.image && <Image src={partner.image.url} />}</Partner>
          ))}
        </Slider>
      </SliderStyled>
    </Container>
  );
};

BlockPartners.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
export default BlockPartners;
