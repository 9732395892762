import {hasProperty} from './functions';

const Icons = {
  youtube: 'fa-youtube',
  facebook: 'fa-facebook-f',
  whatsapp: 'fa-whatsapp',
  instagram: 'fa-instagram',
  messenger: 'fa-facebook-messenger',
  'facebook-messenger': 'fa-facebook-messenger',
  twitter: 'fa-twitter',
  twitch: 'fa-twitch',
  linkedin: 'fa-linkedin',
  line: 'fa-line',
  pinterest: 'fa-pinterest',
  skype: 'fa-skype',
  snapchat: 'fa-snapchat',
  reddit: 'fa-reddit',
};

export const getIcons = (icon) => {
  return hasProperty(Icons, icon.toLowerCase())
    ? Icons[icon.toLowerCase()]
    : `fa-${icon.toLowerCase()}`;
};
