import React, {useCallback, useEffect, useState} from 'react';
import {Col, Container, Row} from 'reactstrap';
import {getEndpoints} from '../../services/endpoints/getEndpoints';
import Base from '../../components/Base';
import Breadcrumb from '../../components/Breadcrumb';
import {hasProperty} from '../../utils/functions';
import PartnerItem from '../../components/Partners/PartnerItem';

const PartnersList = ({match, history}) => {
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState([]);
  const {slug} = match.params;

  const loadContents = useCallback(async () => {
    try {
      const endpoint = atob(slug);
      try {
        const response = await getEndpoints(endpoint);
        const {data} = response;
        setContent(data || []);
      } catch (error) {
        console.error('loadContents.getEndpoints', error);
        history.push('/404');
      }
    } catch (e) {
      console.error('loadContents.endpoint', e);
      history.push('/404');
    }

    setLoading(false);
  }, [slug, history]);

  useEffect(() => {
    loadContents();
    return () => {
      setLoading(true);
    };
  }, [loadContents]);

  if (loading) {
    return null;
  }

  return (
    <Base slug={['list-all-pages']}>
      <Container className="pb-5">
        <Breadcrumb contents={[{title: 'Parceiros', url: '/parceiros'}, {title: content.name}]} />

        <h1 style={{marginBottom: 15}}>{content.name}</h1>

        <div style={{marginBottom: 45}} dangerouslySetInnerHTML={{__html: content.description}} />

        {hasProperty(content, 'partners') && content.partners.length ? (
          <Row>
            {content.partners.map((partner) => (
              <Col key={partner['@id']} xs={12} sm={6} md={4} lg={3}>
                <PartnerItem partner={partner} />
              </Col>
            ))}
          </Row>
        ) : (
          <p>Sem conteúdos</p>
        )}
      </Container>
    </Base>
  );
};

export default PartnersList;
