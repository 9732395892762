export const settings = {
  titleSite: 'OTT Demo',
  perPage: 12,
  itemsCarousel: 4,
  copyright: 'OTT Demo 2021',
  enableImageBuild: false,
  pagination: {
    pageRangeDisplayed: 3,
    hideDisabled: true,
    hideFirstLastPages: false,
    hideNavigation: true,
    linkClassFirst: 'arrow first',
    linkClassPrev: 'arrow prev',
    linkClassNext: 'arrow next',
    linkClassLast: 'arrow last',
  },
  codes: {
    hotjar: {
      hjid: 1857620,
      hjsv: 6,
    },
    gtm: {
      uaId: 'UA-161429558-2',
      gtmId: 'GTM-MQ4DM2L',
      awId: 'AW-742442251',
    },
  },
};
