import styled from 'styled-components';

export const Objective = styled.div`
  margin-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
`;

export const Title = styled.h3`
  color: #003475;
  font-size: 24px;
  line-height: 30px;
  font-weight: bold;
  margin-bottom: 20px;
`;

export const Description = styled.div``;
