import React, {useState} from 'react';
import queryString from 'query-string';
import {useHistory} from 'react-router-dom';
import {Col, Row} from 'reactstrap';
import styled from 'styled-components';
import {hasProperty} from '../../../utils/functions';
import {Button, Checkbox, Search} from '../../../components/Elements';
import {getTypeTitle} from '../../../components/Contents/Enum/types';

const SearchAndCategoriesFilter = () => {
  const history = useHistory();
  const params = queryString.parse(history.location.search);
  const [query, setQuery] = useState(hasProperty(params, 'query') ? params.query : '');
  const [values, setValues] = useState({
    Video: hasProperty(params, 'filter') ? params.filter.indexOf('Video') !== -1 : false,
    Webinar: hasProperty(params, 'filter') ? params.filter.indexOf('Webinar') !== -1 : false,
    Article: hasProperty(params, 'filter') ? params.filter.indexOf('Article') !== -1 : false,
    Podcast: hasProperty(params, 'filter') ? params.filter.indexOf('Podcast') !== -1 : false,
    Ebook: hasProperty(params, 'filter') ? params.filter.indexOf('Ebook') !== -1 : false,
    Infographic: hasProperty(params, 'filter')
      ? params.filter.indexOf('Infographic') !== -1
      : false,
    VideoConference: hasProperty(params, 'filter')
      ? params.filter.indexOf('VideoConference') !== -1
      : false,
  });
  const [dropdown, setDropdown] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();

    if (query.length >= 3 || Object.values(values).includes(true)) {
      history.push({
        pathname: history.location.pathname,
        search: `?query=${query}&filter=${Object.keys(values)
          .filter((key) => values[key])
          .map((key) => key)
          .join(',')}`,
      });
    } else {
      alert('Mínimo de 3 letras');
    }
  };

  const setFieldValueQuery = (e) => {
    const {value} = e.currentTarget;
    setQuery(value);
  };

  const setFieldValue = (e) => {
    const {name, checked} = e.currentTarget;

    setValues((prevValues) => ({
      ...prevValues,
      [name]: checked,
    }));
  };

  const clearForm = () => {
    history.push(history.location.pathname);
  };

  return (
    <Row>
      <Col xs={12} sm={6} md={4}>
        <Search
          placeholder="Buscar Curso"
          onSubmit={onSubmit}
          setFieldValue={setFieldValueQuery}
          defaultValue={query}
        />
      </Col>
      <Col className="mb-4" xs={9} sm={4} md={3} lg={2}>
        <DropdownCategories>
          <Title onClick={() => setDropdown((prev) => !prev)}>
            Categorias
            <i className="far fa-angle-down" />
          </Title>
          {dropdown && (
            <Dropdown>
              {Object.keys(values).map((val) => (
                <Checkbox
                  key={val}
                  checked={values[val]}
                  label={getTypeTitle(val)}
                  name={val}
                  setFieldValue={setFieldValue}
                />
              ))}
              <DropdownItem onClick={clearForm}>Limpar filtro</DropdownItem>
            </Dropdown>
          )}
        </DropdownCategories>
      </Col>
      {Object.values(values).includes(true) && (
        <Col className="mb-4" xs={3} sm={4} md={3} lg={2}>
          <Button type="button" color="primary-light" onClick={(e) => onSubmit(e)}>
            <i className="fal fa-filter d-md-none" />
            <div className="d-none d-md-block">Filtrar</div>
          </Button>
        </Col>
      )}
    </Row>
  );
};

const DropdownCategories = styled.div`
  position: relative;
  z-index: 999;
`;

const Title = styled.div`
  border-bottom: 1px solid #00b0e6;
  background: #f7f7f7;
  font-size: 14px;
  padding: 8px 12px;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  i {
    font-size: 17px;
    margin-left: 5px;
  }
`;

const Dropdown = styled.div`
  position: absolute;
  width: 100%;
  padding: 10px;
  background: #f7f7f7;
  box-shadow: 1px 1px 6px #a3a3a3;
`;

const DropdownItem = styled.div`
  padding: 5px 0;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
`;

export default SearchAndCategoriesFilter;
