import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Link, useHistory} from 'react-router-dom';
import toSlugCase from 'to-slug-case';
import {getType, getTitleByType} from '../../../components/Contents/Enum/types';
import {hasProperty} from '../../../utils/functions';
import {getImagePlaylistItem} from '../../../utils/buildImageUrl';

const Playlist = ({content, playlist}) => {
  const histoty = useHistory();
  const {search} = histoty.location;

  const hasWatched = (current) => {
    const userCollectionView =
      current.userCollections.filter((item) => item['@type'] === 'UserCollectionView')[0] || {};
    if (hasProperty(userCollectionView, 'watched')) {
      return userCollectionView.watched;
    }
    return false;
  };

  return playlist.map((item) => (
    <Item
      key={item['@id']}
      className={content.id === item.id ? 'active' : ''}
      to={`/cursos/${toSlugCase(getType(item['@type']))}/${item.slug}${search}`}>
      {!!item.thumb && <Image src={getImagePlaylistItem(item.thumb.url)} alt={item.title} />}
      {hasWatched(item) && <Watched className="far fa-check" title="Conteúdo já assistido" />}
      <MetaInfo>
        <Title title={item.title}>{item.title}</Title>
        <Type>{getTitleByType(item['@type'])}</Type>
      </MetaInfo>
    </Item>
  ));
};

const Item = styled(Link)`
  display: flex;
  margin-bottom: 10px;
  text-decoration: none;
  position: relative;
  &.active {
    background: #eee;
  }
`;

const Image = styled.img`
  max-width: 100%;
  width: 80px;
  object-fit: contain;
  margin-right: 5px;
`;

const Watched = styled.i`
  position: absolute;
  top: 5px;
  left: 5px;
  background: #00985f;
  color: #fff;
  font-size: 8px;
  padding: 3px;
  border-radius: 3px;
  z-index: 9;
`;

const MetaInfo = styled.div`
  position: relative;
  overflow: hidden;
`;

const Title = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  color: #004685;
  font-size: 14px;
  letter-spacing: 0.5px;
`;

const Type = styled.div`
  font-size: 13px;
  color: #666e7a;
  letter-spacing: 0.5px;
`;

Playlist.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  playlist: PropTypes.array.isRequired,
};
export default Playlist;
