import styled from 'styled-components';

export const BurguerMenu = styled.div`
  font-size: 30px;
  color: #fff;
  margin-top: 20px;
  text-align: right;
  @media (min-width: 576px) {
    display: none;
  }
`;

export const FloatingButton = styled.div`
  position: fixed;
  bottom: 15px;
  right: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  button {
    display: flex;
    align-items: center;
    i {
      margin-left: 5px;
      font-size: 18px;
    }
  }
`;

export const BtnClose = styled.i`
  width: 20px;
  height: 20px;
  background: #666e7a;
  color: #fff;
  font-size: 14px;
  line-height: 14px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const Navbar = styled.nav`
  position: relative;
  z-index: 9999;
  width: 100%;
`;

export const NavMenu = styled.nav`
  ul {
    list-style: none;
    padding: 0;
  }
  @media (min-width: 576px) {
    > div {
      height: auto !important;
      overflow: inherit !important;
      > div {
        display: block !important;
      }
    }
  }
  > div > div > ul {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    @media (min-width: 576px) {
      flex-direction: row;
    }
    > li {
      position: relative;
      a {
        position: relative;
        color: #ffffff;
        text-decoration: none;
        padding: 10px;
        font-size: 15px;
        font-weight: 600;
        font-family: Muli, sans-serif;
        display: flex;
        align-items: center;
        min-height: 40px;
        .icon-user {
          margin-right: 5px;
          position: relative;
          font-size: 14px;
        }
        > i {
          &.fa-angle-down {
            display: none;
          }
          @media (min-width: 576px) {
            &.fa-angle-down {
              display: inline-block;
            }
            margin-left: 8px;
          }
          font-size: 20px;
          line-height: 0.6;
        }
      }
      &:hover > ul {
        display: block;
      }
      ul {
        padding-left: 15px;
        @media (min-width: 576px) {
          padding-left: 0;
          display: none;
          position: absolute;
          width: 100%;
          min-width: 220px;
          max-width: 220px;
          max-height: 340px;
          overflow: auto;
          border: 1px solid #e3e5e7;
          background-color: #ffffff;
          box-shadow: 0 6px 6px rgba(102, 110, 122, 0.1);
          color: #003475;
          z-index: 2;
        }
        li {
          a {
            color: #003475;
            &:hover {
              background-color: #003475;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
`;
