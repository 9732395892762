import api from '../../api';

const getPage = (slug, params = {}) =>
  api.get(`/pages/slug/${slug}`, {
    params: {
      ...params,
    },
  });

export {getPage};
