import React from 'react';
import {positions, Provider} from 'react-alert';
import {hotjar} from 'react-hotjar';
import ReactGA from 'react-ga';
import AlertMUITemplate from 'react-alert-template-mui';
import {settings} from './config/settings';
import Routes from './routes';

const options = {
  position: positions.MIDDLE,
};

function App() {
  hotjar.initialize(settings.codes.hotjar.hjid, settings.codes.hotjar.hjsv);
  ReactGA.initialize(settings.codes.gtm.uaId, {
    debug: false,
  });
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <Provider template={AlertMUITemplate} {...options}>
      <Routes />
    </Provider>
  );
}

export default App;
