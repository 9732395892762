import React from 'react';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';
import toSlugCase from 'to-slug-case';
import {useHistory} from 'react-router-dom';
import {useAlert} from 'react-alert';
import queryString from 'query-string';
import noImage from '../../../assets/images/no-course.jpg';
import {
  Box,
  ButtonRemoveFav,
  Category,
  Description,
  FreeContent,
  Image,
  Item,
  LinkImage,
  MetaInfo,
  Partner,
  TagsButtons,
  Title,
  WatchedContent,
} from './styled';
import {hasProperty} from '../../../utils/functions';
import {getType} from '../Enum/types';
import {getKeys, getType as getTypeCategory} from '../Enum/categories';
import Collections from './Collections';
import {postFavorite} from '../../../services/endpoints/contents/contents';
import {getImageContentItem} from '../../../utils/buildImageUrl';

const ContentItem = ({content, settings, playlist, showFavoriteRemove, addClass}) => {
  const alert = useAlert();
  const type = content['@type'];
  const urlType = `/cursos/${toSlugCase(getType(type))}`;
  const history = useHistory();
  const params = queryString.parse(history.location.search);
  const watched = (() => {
    const userCollectionView =
      (hasProperty(content, 'userCollections') &&
        content.userCollections.filter((item) => item['@type'] === 'UserCollectionView')[0]) ||
      {};
    if (hasProperty(userCollectionView, 'watched')) {
      return userCollectionView.watched;
    }
    return false;
  })();

  const getProperty = (field, attr, defaultValue) => {
    if (settings && hasProperty(settings, field) && hasProperty(settings[field], attr)) {
      return settings[field][attr];
    }
    return defaultValue;
  };

  const buildUrl = () => {
    const urlParams = (() => {
      if (hasProperty(params, 'by') && getKeys().includes(params.by)) {
        if (params.typeFilter) {
          try {
            const id = atob(params.typeFilter).replace(`/v1/${getTypeCategory(params.by)}/`, '');
            const typeFilterTitle = getProperty('breadcrumb', 'typeFilterTitle', '');
            let urlEncoded = btoa(
              `by=${params.by}&typeFilterId=${id}&typeFilter=${params.typeFilter}`,
            );
            if (typeFilterTitle) {
              urlEncoded += `&typeFilterTitle=${typeFilterTitle}`;
            }

            return `?playlist=${urlEncoded}`;
          } catch (e) {
            return `?playlist=${btoa(`by=${params.by}`)}`;
          }
        }
        return `?playlist=${btoa(`by=${params.by}`)}`;
      }
      if (hasProperty(params, 'playlist')) {
        try {
          return `?playlist=${params.playlist}`;
        } catch (e) {
          return '';
        }
      }
      if (playlist) {
        try {
          return `?playlist=${playlist}`;
        } catch (e) {
          return '';
        }
      }
      return '';
    })();

    switch (type) {
      case 'Webinar':
        return `${urlType}/${content.slug}${urlParams}`; // tinha um /iniciar
      case 'VideoConference':
        return `${urlType}/${content.slug}${urlParams}`;
      default:
        return `${urlType}/${content.slug}${urlParams}`;
    }
  };
  const urlContent = buildUrl();

  const BuildImage = () => {
    const image = content.thumb;

    if (image) {
      return <Image src={getImageContentItem(image.url)} alt={content.title} />;
    }
    return <Image src={noImage} alt={content.title} />;
  };

  const removeFavorite = async () => {
    try {
      await postFavorite({
        content: content['@id'],
      });
      history.go();
    } catch (e) {
      alert.show(`Ocorreu um erro excluir o conteúdo\n${content.title}`);
    }
  };

  return (
    <Box showFavoriteRemove={showFavoriteRemove}>
      {showFavoriteRemove && (
        <ButtonRemoveFav onClick={removeFavorite}>
          <i className="fal fa-times" />
        </ButtonRemoveFav>
      )}
      <Item
        noHover={hasProperty(settings, 'noHover') ? settings.noHover : false}
        className={addClass}>
        <LinkImage to={urlContent}>
          {content.free && <FreeContent>Gratuito</FreeContent>}
          {watched && <WatchedContent title="Conteúdo assistido">Assistido</WatchedContent>}
          <BuildImage />
        </LinkImage>

        <MetaInfo>
          {!getProperty('collections', 'hide', false) && <Collections content={content} />}

          <TagsButtons>
            {!getProperty('category', 'hide', false) && getType(type) && (
              <Category to={urlType}>{getType(type)}</Category>
            )}

            {!getProperty('parner', 'hide', false) && content.partner && (
              <Partner to={`/cursos?by=parceiros&typeFilter=${btoa(content.partner['@id'])}`}>
                {content.partner.name}
              </Partner>
            )}
          </TagsButtons>

          {!getProperty('title', 'hide', false) && (
            <Title
              to={urlContent}
              height={getProperty('title', 'truncate', 2)}
              disableheight={getProperty('title', 'disableHeight', false).toString()}>
              <Truncate lines={getProperty('title', 'truncate', 2)}>{content.title}</Truncate>
            </Title>
          )}

          {!getProperty('description', 'hide', false) && (
            <Description
              height={getProperty('description', 'truncate', 2)}
              disableheight={getProperty('description', 'disableHeight', false).toString()}>
              <Truncate lines={getProperty('description', 'truncate', 2)}>
                <div dangerouslySetInnerHTML={{__html: content.summary}} />
              </Truncate>
            </Description>
          )}
        </MetaInfo>
      </Item>
    </Box>
  );
};

ContentItem.propTypes = {
  content: PropTypes.object.isRequired,
  settings: PropTypes.object,
  showFavoriteRemove: PropTypes.bool,
  playlist: PropTypes.string,
  addClass: PropTypes.string,
};

export {ContentItem};
