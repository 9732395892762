import api from '../../api';

export const getContents = (params = {}, endpoint = '/contents') => {
  return api.get(endpoint, {
    params: {
      ...params,
    },
  });
};

export const getContent = (slug) => {
  return api.get(`/contents/slug/${slug}`, {
    params: {},
  });
};

export const getRelatedContents = (idContent, params = {perPage: 4}) => {
  return api.get(`/contents/${idContent}/related_contents`, {
    params: {...params},
  });
};

export const getExam = (idContent) => {
  return api.get(`/contents/${idContent}/exam`, {
    params: {},
  });
};

export const postFavorite = (params = {}) => {
  return api.post('/user_collection_favorites', params);
};

export const postRatings = (params = {}) => {
  return api.post('/user_collection_ratings', params);
};

export const getTrails = (params, extraHeaders = {}) => {
  return api.get('/trails', {
    ...extraHeaders,
    params: {
      active: true,
      'order[position]': 'desc',
      ...params,
    },
  });
};

export const getGroupPartners = (params, extraHeaders = {}) => {
  return api.get('/partner_groups', {
    ...extraHeaders,
    params: {
      ...params,
    },
  });
};

export const getPartners = (params, extraHeaders = {}) => {
  return api.get('/partners', {
    ...extraHeaders,
    params: {
      active: true,
      'order[position]': 'desc',
      ...params,
    },
  });
};

export const getTags = (params, extraHeaders = {}) => {
  return api.get('/tags', {
    ...extraHeaders,
    params: {
      active: true,
      'order[position]': 'asc',
      ...params,
    },
  });
};
export const getThemes = (params, extraHeaders = {}) => {
  return api.get('/themes', {
    ...extraHeaders,
    params: {
      active: true,
      'order[position]': 'asc',
      ...params,
    },
  });
};

/*
 * @params progress
 * @params percent
 * @params content
 * @params attachment
 * */
export const postContentView = (params = {}) => {
  return api.post('/user_collection_views', params);
};

export const getMeExam = (idContent) => {
  return api.get(`/current_customer_content_exam_application?content=${idContent}`);
};

/*
 * Exemplo de uso
 * {
 *    "content": "/v1/contents/3",
 *    "examApplicationAnswers": [
 *      {
 *        "question": "/v1/exam_questions/1",
 *        "alternative": "/v1/exam_question_alternatives/1"
 *      },
 *    ]
 * }
 * */
export const postExamResolutions = (params = {}) => {
  return api.post('/exam_application_resolutions', params);
};
