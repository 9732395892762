import React from 'react';
import PropTypes from 'prop-types';
import {NavFooter} from './styled';
import {Link} from '../Elements';

const MenuFooter = ({content}) => {
  const {menuItems = []} = content;

  return (
    <>
      <NavFooter>
        {menuItems.map((menu, index) => (
          <React.Fragment key={index}>
            <Link to={menu.url} target={menu.target}>
              {menu.title}
            </Link>
          </React.Fragment>
        ))}
      </NavFooter>
    </>
  );
};

MenuFooter.propTypes = {
  content: PropTypes.object.isRequired,
};

export default MenuFooter;
