import React from 'react';
import PropTypes from 'prop-types';

import {ErrorMessage, Form, Formik} from 'formik';
import {Col, Row} from 'reactstrap';
import {mapInitialValues} from './util/mapInitialValues';
import {mapValidations} from './util/mapValidations';
import MakeField from './MakeField';
import {hasProperty} from '../../utils/functions';

const GenerateForm = ({groupFields, onSubmit, settings}) => {
  const getSettings = (type) => {
    switch (type) {
      case 'button.text':
        if (hasProperty(settings, 'button') && hasProperty(settings.button, 'text'))
          return settings.button.text;
        return 'Salvar';
      case 'button.textSubmitting':
        if (hasProperty(settings, 'button') && hasProperty(settings.button, 'textSubmitting'))
          return settings.button.textSubmitting;
        return 'Salvando...';
      default:
        return '';
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={mapInitialValues(groupFields)}
      validationSchema={mapValidations(groupFields)}
      onSubmit={(values, actions) => {
        onSubmit(values, actions);
      }}>
      {({isSubmitting}) => (
        <Form>
          {groupFields.map((group, index) => (
            <div key={`group-${index}`} className="group-field" id={`group-${index}`}>
              {group.name !== '' && <label htmlFor={`group-${index}`}>{group.name}</label>}

              <Row>
                {group.fields.map((field, idx) => (
                  <Col
                    key={`field-${idx}`}
                    xs={hasProperty(field.columns) ? field.columns.xs : 12}
                    {...field.columns}>
                    <div className="element-field">
                      {field.hasPlaceholder === false && (
                        <label className="label-title" htmlFor={field.name}>
                          {field.label}
                        </label>
                      )}
                      <MakeField {...field} />
                      <ErrorMessage component="span" name={field.name} />
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          ))}

          <Row>
            <Col xs={12}>
              <div className="footer-buttons">
                <button type="submit" className="btn btn-primary">
                  {isSubmitting ? getSettings('button.textSubmitting') : getSettings('button.text')}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

GenerateForm.propTypes = {
  groupFields: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  settings: PropTypes.object,
};

export default GenerateForm;
