import styled from 'styled-components';

export const SelectGroup = styled.div`
  background-color: #ebecee;
  padding: 5px 10px;
  border-bottom: 1px solid #00b0e6;
`;

export const Label = styled.div`
  font-size: 12px;
  color: #666e7a;
`;

export const Select = styled.select`
  outline: none;
  border: 0;
  background: none;
  width: 100%;
  font-size: 14px;
  height: 25px;
  option {
  }
`;
