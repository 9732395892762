import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {getImageContentShow} from '../../../utils/buildImageUrl';

const ContentFile = ({content}) => {
  const RenderImage = () => {
    if (content.cover) {
      return <ImageResponsive src={getImageContentShow(content.cover.url)} />;
    }
    if (content.thumb) {
      return <ImageResponsive src={getImageContentShow(content.thumb.url)} />;
    }
    return null;
  };

  return (
    <AdjustImage>
      <RenderImage />
      {content.file && (
        <AlignCenter>
          <ContentFileLink
            href={content.file.url}
            download={content.file.url}
            target="_blank"
            rel="noopener noreferrer">
            <i className="fal fa-file-download" />
            Clique aqui para realizar download
          </ContentFileLink>
        </AlignCenter>
      )}
    </AdjustImage>
  );
};

const AdjustImage = styled.div`
  position: relative;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
  margin-bottom: 30px;
`;

const ImageResponsive = styled.img`
  max-width: 100%;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`;

const AlignCenter = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1;
  }
`;

const ContentFileLink = styled.a`
  z-index: 2;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  i {
    font-size: 50px;
    margin-bottom: 20px;
  }
`;

ContentFile.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
export {ContentFile};
