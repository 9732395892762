import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import {useHistory} from 'react-router-dom';
import {Col} from 'reactstrap';
import {Label, Select, SelectGroup} from './styled';
import {getPartners, getThemes, getTrails} from '../../../services/endpoints/contents/contents';
import {hasProperty} from '../../../utils/functions';
import {getKeys, getTitle} from '../../../components/Contents/Enum/categories';

const SelectCategories = ({by, setFieldValue}) => {
  const isMounted = useRef(true);
  const [loading, setLoading] = useState(true);
  const [contents, setContents] = useState([]);
  const history = useHistory();
  const params = queryString.parse(history.location.search);

  const getData = useCallback(async () => {
    switch (by) {
      case 'objetivos':
        return getTrails();
      case 'parceiros':
        return getPartners();
      case 'temas':
        return getThemes();
      default:
        return null;
    }
  }, [by]);

  const loadContents = useCallback(async () => {
    try {
      const response = await getData();
      const {data} = response;
      setContents(data['hydra:member'] || []);
    } catch (error) {
      console.error('SelectCategories.loadContents', error);
    }
    setLoading(false);
  }, [getData]);

  useEffect(() => {
    if (isMounted.current && getKeys().includes(by)) {
      loadContents();
    }
    return () => {
      setLoading(true);
      setContents([]);
      isMounted.current = false;
    };
  }, [by, loadContents]);

  if (loading || !contents.length) {
    return null;
  }

  return (
    <Col xs={12} md={6} lg={4} className="mb-3 mb-lg-0">
      <SelectGroup>
        <Label>{getTitle(by)}</Label>
        <Select
          type="select"
          name="typeFilter"
          onChange={setFieldValue}
          defaultValue={params.typeFilter}>
          <option value="">Selecione</option>
          {contents.map((content) => (
            <option key={content['@id']} value={btoa(content['@id'])}>
              {hasProperty(content, 'title') ? content.title : content.name}
            </option>
          ))}
        </Select>
      </SelectGroup>
    </Col>
  );
};

SelectCategories.propTypes = {
  by: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default SelectCategories;
