import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {BreadcrumbItem, Breadcrumb as BreadcrumbStyled} from './styled';

const Breadcrumb = ({contents}) => {
  return (
    <BreadcrumbStyled>
      <BreadcrumbItem>
        <Link to="/">
          <i className="far fa-home" />
        </Link>
      </BreadcrumbItem>
      {contents.map((content, index) => (
        <BreadcrumbItem key={`breadcrumb${index}`}>
          {content.url ? (
            <>
              <Link to={content.url}>{content.title}</Link>
              <i className="far fa-angle-right" />
            </>
          ) : (
            <span>{content.title}</span>
          )}
        </BreadcrumbItem>
      ))}
    </BreadcrumbStyled>
  );
};

Breadcrumb.propTypes = {
  contents: PropTypes.array.isRequired,
};

export default Breadcrumb;
