import styled from 'styled-components';

export const NavFooter = styled.div`
  display: flex;
  margin-left: -5px;
  flex-direction: column;
  @media (min-width: 576px) {
    flex-direction: row;
  }
  a {
    margin: 0 10px;
    padding: 5px 0;
    display: inline-block;
    font-size: 13px;
    color: #ffffff;
    text-decoration: none;
    transition: color 300ms linear;
    position: relative;
    &:hover {
      color: #00b0e6;
    }
    &:last-child::after {
      display: none;
    }
    @media (min-width: 576px) {
      &:after {
        content: '';
        height: 100%;
        @media (min-width: 1200px) {
          height: 10px;
        }
        position: absolute;
        right: -10px;
        width: 1px;
        background: #fff;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
`;
