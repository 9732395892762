import React, {useState} from 'react';
import queryString from 'query-string';
import {useHistory} from 'react-router-dom';
import {Col, Row} from 'reactstrap';
import {hasProperty} from '../../../utils/functions';
import {Search} from '../../../components/Elements';

const SearchBar = () => {
  const history = useHistory();
  const params = queryString.parse(history.location.search);
  const [query, setQuery] = useState(hasProperty(params, 'query') ? params.query : '');

  const onSubmit = (e) => {
    e.preventDefault();

    if (query.length >= 3) {
      history.push({
        pathname: history.location.pathname,
        search: `?query=${query}`,
      });
    } else {
      alert('Mínimo de 3 letras');
    }
  };

  const setFieldValueQuery = (e) => {
    const {value} = e.currentTarget;
    setQuery(value);
  };

  return (
    <Row className="flex-row-reverse">
      <Col xs={6} sm={6} md={4}>
        <Search
          placeholder="Buscar Curso"
          onSubmit={onSubmit}
          setFieldValue={setFieldValueQuery}
          defaultValue={query}
        />
      </Col>
    </Row>
  );
};

export default SearchBar;
