import React from 'react';
import PropTypes from 'prop-types';
import Base from '../components/Base';

const Pages = ({match}) => {
  const {slug = 'home-logada'} = match.params;

  return (
    <Base
      slug={[slug, 'list-all-pages']}
      hideBreadcrumb={slug === 'home-logada'}
    />
  );
};

Pages.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]),
};

export default Pages;
