import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Box, RedesSociaisItems} from './styled';
import Logo from '../../assets/images/logo.png';
import {settings} from '../../config/settings';

const RedesSociais = ({content}) => {
  const {menuItems = []} = content;

  return (
    <Box>
      <Link to="/" className="logo-rodape">
        <img className="img-fluid" src={Logo} alt={settings.titleSite} />
      </Link>

      <RedesSociaisItems>
        {menuItems.map((menu, index) => (
          <a key={index} href={menu.url} target="_blank" rel="noopener noreferrer">
            <i className={menu.icon} />
          </a>
        ))}
      </RedesSociaisItems>
    </Box>
  );
};

RedesSociais.propTypes = {
  content: PropTypes.object.isRequired,
};

export default RedesSociais;
