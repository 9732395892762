import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Container} from 'reactstrap';
import queryString from 'query-string';
import Base from '../../components/Base';
import Breadcrumb from '../../components/Breadcrumb';
import {getTypeTitle} from '../../components/Contents/Enum/types';
import {
  getContent,
  getExam,
  getMeExam,
  getRelatedContents,
} from '../../services/endpoints/contents/contents';
import ContentShowByType from './_contents/ContentShowByType';
import {getToken} from '../../utils/auth';
import {hasProperty} from '../../utils/functions';
import {
  getFilterCategory,
  getKeys,
  getTitle,
  getType,
} from '../../components/Contents/Enum/categories';
import {getEndpoints} from '../../services/endpoints/getEndpoints';

const ContentShow = ({match, location}) => {
  const isMounted = useRef(true);
  const {type, slug} = match.params;
  const titleType = getTypeTitle(type);
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState({});
  const [relatedContents, setRelatedContents] = useState([]);
  const [preferences, setPreferences] = useState({
    watched: false, // Se o vídeo foi assistido
    exam: {}, // Se tem exame retorna o exame
    mandatoryApproval: false, // requer fazer a prova, para obter o certificado
    examApproval: false, // se foi aprovado no resolvado
    userGradeObtained: 0, // nota máxima obtida no exame
  });
  const search = queryString.parse(location.search);
  const [playlist, setPlaylist] = useState([]);

  const loadContent = useCallback(async () => {
    try {
      const response = await getContent(slug);
      const {data} = response;
      const {userCollections} = data;
      const params = {};
      const relatedContentsData = [];

      if (getToken()) {
        try {
          const resRelatedContents = await getRelatedContents(data.id);
          relatedContentsData.push(...resRelatedContents.data['hydra:member']);
        } catch (e) {
          console.error('ContentList.loadContent.getContents.getRelatedContents', e);
        }

        // Checks whether user has watched the content
        const UserCollectionView =
          userCollections.filter((item) => item['@type'] === 'UserCollectionView')[0] || {};
        if (hasProperty(UserCollectionView, 'watched')) {
          Object.assign(params, {
            watched: UserCollectionView.watched,
          });
        }

        try {
          // Checks whether the course has an exam
          const resExam = await getExam(data.id);

          Object.assign(params, {
            exam: resExam.data || {},
            mandatoryApproval: resExam.data.mandatoryApproval || false,
          });

          try {
            // Checks whether the user has taken an exam
            const myExam = await getMeExam(data.id);
            const myExameData = myExam.data['hydra:member'] || [];
            Object.assign(params, {
              examApproval: !!myExameData.filter(
                (item) => item.gradeObtained >= resExam.data.approvalPercentage,
              ).length,
              userGradeObtained: Math.max(...myExameData.map((item) => item.gradeObtained)) || 0,
            });
          } catch (e) {
            if (e.response.status === 404) {
              Object.assign(params, {
                examApproval: false,
              });
            } else {
              console.error('ContentList.loadContent.getContents.getExam.getMeExam', e);
            }
          }
        } catch (e) {
          if (e.response.status === 404) {
            Object.assign(params, {
              exam: {},
              mandatoryApproval: false,
            });
          } else {
            console.error('ContentList.loadContent.getContents.getExam', e);
          }
        }
      }

      setContent(data || {});
      setRelatedContents(relatedContentsData);
      setPreferences((prevState) => Object.assign(prevState, params));
      setLoading(false);
    } catch (e) {
      console.error('ContentList.loadContent.getContents', e);
    }
  }, [slug]);

  useEffect(() => {
    if (isMounted.current) {
      loadContent();
    }
    return () => {
      isMounted.current = false;
    };
  }, [loadContent]);

  useEffect(() => {
    if (isMounted.current && hasProperty(search, 'playlist')) {
      (async () => {
        try {
          let endpoint = '';
          let pl = atob(search.playlist);
          if (pl.indexOf('by=') !== -1) {
            pl = queryString.parse(pl);
          }
          if (hasProperty(pl, 'by') && getKeys().includes(pl.by)) {
            const options = {};
            if (hasProperty(pl, 'typeFilterId')) {
              Object.assign(options, {
                [getFilterCategory(pl.by)]: parseInt(pl.typeFilterId, 10),
              });
            }
            Object.assign(options, {
              [`exists[${getType(pl.by)}]`]: true,
              'order[publishAt]': 'ASC',
            });
            endpoint = `/v1/contents?${queryString.stringify(options)}`;
          } else if (pl.indexOf('/v1/contents') !== -1) {
            endpoint = pl;
          }

          try {
            const response = await getEndpoints(endpoint);
            const data = response.data['hydra:member'] || [];
            setPlaylist(data);
          } catch (e) {
            console.error('ContentShow.useEffect[search]', e);
            setPlaylist([]);
          }
        } catch (e) {
          setPlaylist([]);
        }
      })();
    }
    return () => {
      isMounted.current = false;
    };
  }, [search]);

  const getBreadcrumb = (() => {
    const breads = [{title: 'Cursos', url: '/cursos'}];
    if (hasProperty(search, 'playlist')) {
      try {
        const params = queryString.parse(atob(search.playlist));
        if (hasProperty(params, 'by') && getKeys().includes(params.by)) {
          breads.push({
            title: getTitle(params.by),
            url: `/cursos?by=${params.by}`,
          });
          if (params.typeFilterTitle && params.typeFilter) {
            breads.push({
              title: params.typeFilterTitle,
              url: `/cursos?by=${params.by}&typeFilter=${params.typeFilter}`,
            });
          }
        }
      } catch (e) {
        console.error('ContentShow.getBreadcrumb', e);
      }
    }
    breads.push(...[{title: titleType, url: `/cursos/${type}`}, {title: content.title}]);
    return breads;
  })();

  if (loading) {
    return null;
  }

  return (
    <Base slug="list-all-pages" hideBreadcrumb>
      <Container className="pb-5">
        <Breadcrumb contents={getBreadcrumb} />

        <ContentShowByType
          content={content}
          relatedContents={relatedContents}
          preferences={preferences}
          playlist={playlist}
        />
      </Container>
    </Base>
  );
};
export default ContentShow;
