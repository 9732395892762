import React from 'react';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';
import {Link} from 'react-router-dom';
import {MetaInfo, Image, Item, ImageAdjust, Description, Btn, SocialMedias, Social} from './styled';
import {getIcons} from '../../utils/icons';

const PartnerItem = ({partner}) => {
  return (
    <Item>
      <Link to={`/cursos?by=parceiros&typeFilter=${btoa(partner['@id'])}`} title={partner.name}>
        <ImageAdjust>
          {partner.image && <Image src={partner.image.url} alt={partner.name} />}
        </ImageAdjust>
      </Link>
      <MetaInfo>
        <Description height={3}>
          <Truncate lines={3}>
            <div
              dangerouslySetInnerHTML={{
                __html: partner.description,
              }}
            />
          </Truncate>
        </Description>

        <Btn
          color="primary-light"
          type={Link}
          to={`/cursos?by=parceiros&typeFilter=${btoa(partner['@id'])}`}>
          Cursos
        </Btn>

        <SocialMedias>
          {partner.socialMedia.map((social) =>
            social.url ? (
              <Social key={social.id} href={social.url} target="_blank" title={social.title}>
                <i className={`fab ${getIcons(social.icon)}`} />
              </Social>
            ) : null,
          )}
        </SocialMedias>
      </MetaInfo>
    </Item>
  );
};

PartnerItem.propTypes = {
  partner: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
export default PartnerItem;
