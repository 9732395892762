import React, {useState} from 'react';
import styled from 'styled-components';
import {Container} from 'reactstrap';
import copyToClipboard from 'copy-to-clipboard';
import {Link} from 'react-router-dom';
import Base from '../../components/Base';
import {getTypeTitle} from '../../components/Contents/Enum/types';
import {Button} from '../../components/Elements';

const ContentInit = ({match}) => {
  const {type, slug} = match.params;
  const titleType = getTypeTitle(type);
  const urlContent = `/cursos/${type}/${slug}`;
  const urlContentComplete = `${window.location.origin}${urlContent}`;
  const [copied, setCopied] = useState(false);

  const copyTransfer = (e) => {
    e.preventDefault();
    copyToClipboard(urlContentComplete);
    setCopied(true);
  };

  return (
    <Base slug="list-all-pages" hideBreadcrumb>
      <Container>
        <AlignContent>
          <h1>Bem-vindo (a) ao {titleType}</h1>

          <MyButton color="secondary" type={Link} to={urlContent}>
            Clique para iniciar
          </MyButton>
          {copied ? (
            <Legend>Link copiado com sucesso!</Legend>
          ) : (
            <>
              <Legend>Copie o link para compartilhar</Legend>
              <InputGroup>
                <Input defaultValue={urlContentComplete} disabled />
                <Copy onClick={copyTransfer}>Copiar</Copy>
              </InputGroup>
            </>
          )}
        </AlignContent>
      </Container>
    </Base>
  );
};

const AlignContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px 0;
  @media (min-width: 992px) {
    padding: 0;
    height: calc(100vh - 320px);
  }
  align-items: center;
  justify-content: center;
`;

const MyButton = styled(Button)`
  height: 55px;
  margin: 20px 0;
  padding: 15px 48px;
  letter-spacing: 1.5px;
  font-size: 16px;
  line-height: 23px;
  font-weight: normal;
`;

const Legend = styled.div`
  font-size: 18px;
`;

const InputGroup = styled.div`
  display: flex;
  width: 100%;
  max-width: 500px;
  margin: 20px 0;
`;

const Input = styled.input`
  display: block;
  width: 100%;
  height: 38px;
  background: none;
  border: 1px solid #cccccc;
  padding: 8px 12px;
  font-size: 14px;
  color: #333333;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  outline: none;
`;

const Copy = styled.button`
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  cursor: pointer;
  border: 0;
  background: #f4a325;
  color: #ffffff;
  padding-left: 10px;
  padding-right: 20px;
  outline: none;
`;

export default ContentInit;
