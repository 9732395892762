import styled, {keyframes} from 'styled-components';
import {slideInUp} from 'react-animations';

export const Item = styled.div`
  display: block !important;
  position: relative;
`;

export const BannerStyled = styled.div`
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
`;

export const BannerMask = styled.div`
  position: relative;
  overflow: hidden;
  &:after {
    content: '';
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
`;

export const BannerImage = styled.img`
  @media (max-width: 575px) {
    max-height: 90vh;
  }
  @media (min-width: 576px) {
    max-width: 100%;
  }
`;

export const PrevArrowStyled = styled.i`
  font-size: 60px;
  color: #ffffff;
  position: absolute;
  z-index: 9;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 1.5em;
  justify-content: center;
  cursor: pointer;
`;

export const NextArrowStyled = styled(PrevArrowStyled)`
  right: 0;
`;

export const MetaInfo = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  z-index: 2;
`;

export const MetaInfoContent = styled.div`
  @media (min-width: 992px) {
    max-width: 600px;
  }
`;

const slideInUpAnimation = keyframes`${slideInUp}`;

export const Title = styled.h2`
  font-size: 45px;
  line-height: 125%;
  letter-spacing: -1px;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 10px;
  animation: 1s ${slideInUpAnimation};
`;

export const Description = styled.p`
  margin: 0;
  font-size: 20px;
  color: #ffffff;
  line-height: 133%;
  animation: 1.2s ${slideInUpAnimation};
`;
