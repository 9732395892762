import React from 'react';
import PropTypes from 'prop-types';
import SambaPlayer from './SambaPlayer';
import {hasProperty} from '../../utils/functions';

const Medias = ({content, nextContent}) => {
  const userCollectionView =
    content.userCollections.filter(
      (collection) => collection['@type'] === 'UserCollectionView',
    )[0] || {};

  const progress = hasProperty(userCollectionView, 'progress') ? userCollectionView.progress : 0;

  if (!content.contentReference) {
    return null;
  }
  return (
    <SambaPlayer
      reference={content.contentReference}
      contentId={content['@id']}
      progressVideo={progress}
      typeContent={content['@type']}
      nextVideo={nextContent}
    />
  );
};

Medias.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  nextContent: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
export default Medias;
