import React from 'react';
import PropTypes from 'prop-types';
import {Link as LinkRouter} from 'react-router-dom';
import {hasExternalLink} from '../../utils/utilities';

const Link = ({to, action, children}) => {
  if (hasExternalLink(to)) {
    return (
      <a href={to || '/'} target={action || '_self'} rel="noopener noreferrer">
        {children}
      </a>
    );
  }

  return (
    <LinkRouter to={to} target={action || '_self'}>
      {children}
    </LinkRouter>
  );
};

Link.propTypes = {
  to: PropTypes.string.isRequired,
  action: PropTypes.string,
  children: PropTypes.any,
};

export {Link};
