import React, {useCallback, useEffect, useState} from 'react';
import {Container} from 'reactstrap';
import queryString from 'query-string';
import {getSearchGlobal} from '../../services/endpoints/general/search';
import Base from '../../components/Base';
import {getType} from '../../components/Contents/Enum/types';
import {ContentItem, Description, SearchTitle, Title, TitleHeader} from './styled';

const Search = ({location}) => {
  const [loading, setLoading] = useState(true);
  const [contents, setContents] = useState([]);
  const {search} = location;
  const {query = ''} = queryString.parse(search);

  const loadContents = useCallback(async () => {
    try {
      const response = await getSearchGlobal(query);
      const {data} = response;
      setContents(data.contents || []);
    } catch (e) {
      console.error('Search.loadContents', e);
    }
    setLoading(false);
  }, [query]);

  useEffect(() => {
    loadContents();
    return () => {
      setLoading(true);
    };
  }, [loadContents]);

  if (loading) {
    return null;
  }

  return (
    <Base slug={['list-all-pages']} hideBreadcrumb>
      <Container className="pb-5">
        <TitleHeader>Resultado completo:</TitleHeader>

        <SearchTitle>{query}</SearchTitle>

        {contents.length ? (
          contents.map((content) => (
            <ContentItem key={content['@id']}>
              <Title to={`/cursos/${getType(content['@type'])}/${content.slug}`}>
                {content.title}
              </Title>
              <Description dangerouslySetInnerHTML={{__html: content.summary}} />
            </ContentItem>
          ))
        ) : (
          <p>Nenhum resultado foi encontrado</p>
        )}
      </Container>
    </Base>
  );
};

export default Search;
