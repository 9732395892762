import React from 'react';
import PropTypes from 'prop-types';
import {ContentItem} from '../../../components/Contents';

const RelatedContents = ({contents}) => {
  if (!contents.length) return null;

  return contents.map(({id, relatedContent}) => (
    <ContentItem
      key={`/v1/related_contents/${id}`}
      content={relatedContent}
      settings={{
        title: {
          truncate: 3,
          disableHeight: true,
        },
        collections: {
          hide: true,
        },
        description: {
          hide: true,
        },
      }}
    />
  ));
};

RelatedContents.propTypes = {
  contents: PropTypes.array.isRequired,
};
export default RelatedContents;
