import styled from 'styled-components';

export const Box = styled.div`
  background: linear-gradient(52.91deg, #27396e 0%, #4ab0e6 100%);
  padding: 45px 0;

  .slick-slider {
    .slick-list {
      margin: 0 -15px;
    }
    .slick-dots {
      li {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.5);
        &.slick-active {
          background-color: #ffd862;
        }
        button {
          opacity: 0;
        }
      }
    }
  }
`;

export const Title = styled.h2`
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: -1px;
  color: #ffffff;
`;

export const Item = styled.div`
  padding: 30px 15px;
  position: relative;
`;

export const PrevArrowStyled = styled.i`
  font-size: 36px;
  line-height: 37px;
  text-align: center;
  position: absolute;
  z-index: 9;
  top: 50%;
  left: 0;
  @media (min-width: 768px) {
    left: -18px;
  }
  margin-top: -70px;
  width: 36px;
  height: 36px;
  background-color: #ffd862;
  color: #ffffff;
  cursor: pointer;
  border-radius: 50%;
  text-indent: -3px;
  &:hover {
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.3);
  }
`;

export const NextArrowStyled = styled(PrevArrowStyled)`
  left: inherit;
  right: 0;
  @media (min-width: 768px) {
    right: -18px;
    left: inherit;
  }
  text-indent: 3px;
`;
