import {settings} from '../config/settings';

export const buildImageUrl = (src, width, height, fit = 'cover') => {
  if (!settings.enableImageBuild) return src;

  const bucket = process.env.REACT_APP_BUCKET;
  const strpos = src.indexOf('/images/');
  const key = src.substring(strpos + 1);
  const prefix = src.substring(0, strpos);
  const urlEncode = btoa(
    JSON.stringify({
      bucket,
      key,
      edits: {
        resize: {
          width,
          height,
          fit,
        },
      },
    }),
  );

  return `${prefix}/${urlEncode}`;
};

export const getImageContentItem = (src) => {
  return buildImageUrl(src, 255, 175);
};

export const getImageContentShow = (src) => {
  return buildImageUrl(src, 802.5, 451.41);
};

export const getImagePlaylistItem = (src) => {
  return buildImageUrl(src, 80, 50);
};

export const getImageFeatures = (src) => {
  return buildImageUrl(src, 270, 150);
};
