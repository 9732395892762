import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Col, Container, Row} from 'reactstrap';
import {Link, useHistory} from 'react-router-dom';

const ShowBannerExam = ({content, preferences}) => {
  const history = useHistory();
  if (!preferences.watched) return null;

  return (
    <Banner>
      <Container>
        <Row className="align-items-end">
          <Col className="mb-30 mb-sm-0" xs={12} sm={7} md={8} lg={9}>
            <Title>Parabéns!</Title>
            <Description>
              Você concluiu 100% do curso. Para finalizar realize a avaliação.
            </Description>
          </Col>
          <Col xs={12} sm={5} md={4} lg={3} className="text-right">
            <Button to={`/avaliacao/${content.id}/${btoa(history.location.pathname)}`}>
              Fazer Avaliação
            </Button>
          </Col>
        </Row>
      </Container>
    </Banner>
  );
};

const Banner = styled.div`
  margin-top: 60px;
  padding: 35px 0;
  background-color: #00985f;
  color: #fff;
  border-radius: 4px;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
`;
const Description = styled.div`
  padding-top: 15px;
  font-size: 14px;
`;

const Button = styled(Link)`
  cursor: pointer;
  padding: 0 35px;
  border: 1px solid #ffffff;
  color: #fff;
  border-radius: 30px;
  line-height: 40px;
  font-size: 12px;
  background: none;
  text-decoration: none;
  display: inline-block;
  transition: all 500ms ease;
  &:hover {
    border-color: transparent;
    background-color: #f4a325;
    -webkit-transform: translate(0px, -3px);
    -ms-transform: translate(0px, -3px);
    transform: translate(0px, -3px);
    color: #333;
  }
`;

ShowBannerExam.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  preferences: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
export default ShowBannerExam;
