import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Link, useHistory} from 'react-router-dom';
import toSlugCase from 'to-slug-case';
import Medias from '../../../components/Medias';
import Collections from '../../../components/Contents/_partials/Collections';
import {
  ContentTitle,
  Description,
  Embed,
  ImageResponsive,
} from '../../../components/Contents/_partials/styled';
import {Button} from '../../../components/Elements';
import {getType} from '../../../components/Contents/Enum/types';
import {postContentView} from '../../../services/endpoints/contents/contents';
import {ContentFile} from './ContentFile';
import {getImageContentShow} from '../../../utils/buildImageUrl';

const ContentType = ({content, playlist, watched}) => {
  const isMounted = React.useRef(true);
  const {location} = useHistory();
  const nextContent = (() => {
    const index = playlist.flatMap((item, idx) => (item.id === content.id ? idx : []));
    if (index.length && playlist[index[0] + 1]) {
      return playlist[index[0] + 1];
    }
    return {};
  })();

  React.useEffect(() => {
    const blackList = ['Video', 'Podcast'];
    if (isMounted.current && !blackList.includes(content['@type'])) {
      if (!watched) {
        setTimeout(async () => {
          try {
            await postContentView({
              content: content['@id'],
              percent: 100,
              progress: 1,
            });
          } catch (e) {
            console.error('ContentType.useEffect.postContentView', e);
          }
        }, 1000 * 15);
      }
    }
    return () => {
      isMounted.current = false;
    };
  }, [content, watched]);

  switch (content['@type']) {
    case 'Video':
    case 'Podcast':
    case 'Infographic':
      return (
        <>
          <Medias content={content} nextContent={nextContent} />
          <Collections content={content} hasShare enabledRatings watched={watched} showWatched />
          <ContentTitle>{content.title}</ContentTitle>
          <Description dangerouslySetInnerHTML={{__html: content.content || content.summary}} />
        </>
      );
    case 'Article':
      return (
        <>
          <ContentTitle>{content.title}</ContentTitle>
          {content.thumb && <ImageResponsive src={getImageContentShow(content.thumb.url)} />}
          <Collections content={content} hasShare enabledRatings watched={watched} showWatched />
          <Description dangerouslySetInnerHTML={{__html: content.content || content.summary}} />
          {!!Object.keys(nextContent).length && (
            <BtnNextContent
              to={`/cursos/${toSlugCase(getType(nextContent['@type']))}/${nextContent.slug}${
                location.search
              }`}>
              Próximo conteúdo
            </BtnNextContent>
          )}
        </>
      );
    case 'Ebook':
      return (
        <>
          <ContentFile content={content} />
          <Collections content={content} hasShare enabledRatings watched={watched} showWatched />
          <ContentTitle>{content.title}</ContentTitle>
          <Description dangerouslySetInnerHTML={{__html: content.content || content.summary}} />
          {!!Object.keys(nextContent).length && (
            <BtnNextContent
              to={`/cursos/${toSlugCase(getType(nextContent['@type']))}/${nextContent.slug}${
                location.search
              }`}>
              Próximo conteúdo
            </BtnNextContent>
          )}
        </>
      );
    case 'VideoConference':
    case 'Webinar':
      return (
        <>
          {content.contentSource && (
            <Embed dangerouslySetInnerHTML={{__html: content.contentSource}} />
          )}
          <Collections content={content} hasShare enabledRatings watched={watched} showWatched />
          <ContentTitle>{content.title}</ContentTitle>
          <Description dangerouslySetInnerHTML={{__html: content.content || content.summary}} />
          {!!Object.keys(nextContent).length && (
            <BtnNextContent
              to={`/cursos/${toSlugCase(getType(nextContent['@type']))}/${nextContent.slug}${
                location.search
              }`}>
              Próximo conteúdo
            </BtnNextContent>
          )}
        </>
      );
    default:
      return (
        <>
          <ContentTitle>{content.title}</ContentTitle>
          <Description dangerouslySetInnerHTML={{__html: content.content || content.summary}} />
        </>
      );
  }
};

const BtnNextContent = styled(Button).attrs({type: Link, color: 'primary'})`
  display: inline-flex;
  align-items: center;
  float: right;
`;

ContentType.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  playlist: PropTypes.array.isRequired,
  watched: PropTypes.bool.isRequired,
};
export default ContentType;
