import React, {useState} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {postRatings} from '../../../services/endpoints/contents/contents';
import {hasProperty} from '../../../utils/functions';

const Ratings = ({content}) => {
  const stars = 5;
  const [rating, setRating] = useState(() => {
    const userCollectionRating = content.userCollections.filter(
      (collection) => collection['@type'] === 'UserCollectionRating',
    )[0];

    return hasProperty(userCollectionRating, 'rating') ? userCollectionRating.rating : 0;
  });

  const handleSubmit = async (numStars) => {
    try {
      await postRatings({
        content: content['@id'],
        rating: numStars,
      });
      setRating(numStars);
    } catch (e) {
      setRating((old) => old);
    }
  };

  const Stars = () => {
    return [...Array(stars).keys()].reverse().map((star) => (
      <React.Fragment key={star}>
        <Star
          id={`${star + 1}star`}
          name="rating"
          onClick={() => handleSubmit(star + 1)}
          defaultChecked={rating === star + 1}
        />
        <StarLabel htmlFor={`${star + 1}star`} title={`${star + 1} estrelas`} />
      </React.Fragment>
    ));
  };

  return (
    <StarsStyled>
      <Stars />
      <CurrentStars title="Média de estrelas por usuário">
        <span>{content.rating || stars}</span>
      </CurrentStars>
    </StarsStyled>
  );
};

const StarsStyled = styled.div`
  display: flex;
  flex-direction: row-reverse;

  > input {
    display: none;
  }

  > input:checked ~ label,
  &:not(:checked) > label:hover,
  &:not(:checked) > label:hover ~ label {
    color: #ffd862;
    font-weight: 900;
    cursor: pointer;
  }

  > label {
    color: #ffd862;
    &:before {
      font-family: 'Font Awesome 5 Pro', sans-serif;
      font-size: 15px;
      display: inline-block;
      content: '\f005';
    }

    &:checked + label:hover,
    &:checked ~ label:hover,
    &:hover ~ input:checked ~ label,
    &:checked ~ label:hover ~ label {
      color: #ffaa00;
    }
  }
`;

const Star = styled.input.attrs({type: 'radio'})``;

const StarLabel = styled.label``;

const CurrentStars = styled.div`
  position: relative;
  margin-right: 15px;
  color: #002c63;
  font-weight: 900;
  font-size: 22px;
  &:before {
    font-family: 'Font Awesome 5 Pro', sans-serif;
    content: '\f005';
  }
  span {
    color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    font-size: 10px;
    text-align: center;
    line-height: 22px;
  }
`;

Ratings.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
export {Ratings};
