import {getMe} from './endpoints/general/currentUser';
import {getEndpoints} from './endpoints/getEndpoints';
import {getAsyncCurrentUser, setAsyncCurrentUser} from '../utils/auth';

const load = async () => {
  try {
    const response = await getMe();
    const res = await getEndpoints(response.data.user['@id']);

    await setAsyncCurrentUser({...response.data, profile: res.data});
    await getAsyncCurrentUser();
  } catch (e) {
    console.error('authenticator.load error', e);
  }
};

export const authenticator = async () => {
  await load();
};
