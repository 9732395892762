import React, {useState} from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import {BtnClose, BurguerMenu, FloatingButton, Navbar, NavMenu} from './styled';
import {Button, Link} from '../Elements';
import {getCurrentUser, getToken, redirectToLogin} from '../../utils/auth';
import BlockNotifications from '../Notifications/BlockNotifications';
import ObjectivesMenu from './_partials/ObjectivesMenu';
import ProfileMenu from './_partials/ProfileMenu';
import PartnersMenu from './_partials/PartnersMenu';
import ThemesMenu from './_partials/ThemesMenu';

const Menu = ({content}) => {
  const {menuItems = []} = content;
  const [toogle, setToogle] = useState(!window.screen.width < 576);
  const [showButtonSingUp, setShowButtonSingUp] = useState(true);
  const {user} = getCurrentUser();
  const hasToken = getToken();
  const menus = [];
  menuItems.forEach((menu) => {
    if (!menu.parentMenu) {
      menus.push(
        Object.assign(menu, {
          childrens:
            menuItems.filter(
              (children) => children.parentMenu && children.parentMenu.id === menu.id,
            ) || [],
        }),
      );
    }
  });

  const handleCloseSignUp = () => {
    setShowButtonSingUp((prev) => !prev);
  };

  const Item = ({item}) => {
    return (
      <li>
        <Link to={item.url} action={item.target}>
          {item.title}
          {item.childrens && item.childrens.length > 0 && <i className="far fa-angle-down" />}
        </Link>
        {item.childrens && item.childrens.length > 0 && (
          <ul>
            {item.childrens.map((children) => (
              <Item key={children.id} item={children} />
            ))}
          </ul>
        )}
      </li>
    );
  };

  return (
    <Navbar>
      <BurguerMenu onClick={() => setToogle((prevValue) => !prevValue)}>
        <i className="far fa-bars" />
      </BurguerMenu>
      <NavMenu>
        <AnimateHeight duration={500} height={!toogle ? 'auto' : 0}>
          <ul>
            {menus.map((item) => (
              <Item key={item.id} item={item} />
            ))}
            <ThemesMenu />
            <ObjectivesMenu />
            <PartnersMenu />
            {hasToken ? (
              <>
                <BlockNotifications />
                <ProfileMenu user={user} />
              </>
            ) : (
              <li>
                <Button
                  onClick={() => {
                    redirectToLogin();
                  }}>
                  Entrar
                </Button>
              </li>
            )}
          </ul>
        </AnimateHeight>
      </NavMenu>
      {!hasToken && (
        <FloatingButton>
          <BtnClose
            className={`fal fa-${showButtonSingUp ? 'times' : 'plus'}`}
            onClick={handleCloseSignUp}
          />
          {showButtonSingUp && (
            <Button
              color="primary"
              onClick={() => {
                window.location.href = `${process.env.REACT_APP_SIGNIN_URL}?signUp=${btoa(
                  document.location.origin,
                )}`;
              }}>
              Cadastre-se
              <i className="far fa-user-circle" />
            </Button>
          )}
        </FloatingButton>
      )}
    </Navbar>
  );
};

Menu.propTypes = {
  content: PropTypes.object.isRequired,
};

export default Menu;
