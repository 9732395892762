import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Checkbox = ({name, label, checked, setFieldValue}) => {
  return (
    <div>
      <Label>
        <Input type="checkbox" name={name} checked={checked} onChange={setFieldValue} />
        {label}
      </Label>
    </div>
  );
};

const Label = styled.label`
  display: flex;
  margin-bottom: 5px;
`;
const Input = styled.input``;

Checkbox.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
export {Checkbox};
