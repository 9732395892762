import {hasProperty} from '../../../utils/functions';

const Categories = {
  objetivos: {
    title: 'Para sua empresa',
    type: 'trails',
    filter: 'trails',
    params: {
      title: 'title',
      description: 'description',
      image: null,
    },
  },
  parceiros: {
    title: 'Parceiros',
    type: 'partners',
    filter: 'partner',
    params: {
      title: 'name',
      description: 'description',
      image: 'image',
    },
  },
  temas: {
    title: 'Para você',
    type: 'themes',
    filter: 'themes',
    params: {
      title: 'title',
      description: 'description',
      image: null,
    },
  },
};

export const getTitle = (key) => {
  return hasProperty(Categories, key) ? Categories[key].title : '';
};

export const getType = (key) => {
  return hasProperty(Categories, key) ? Categories[key].type : '';
};

export const getFilterCategory = (key) => {
  return hasProperty(Categories, key) ? Categories[key].filter : '';
};

export const getParamsCategory = (key) => {
  return hasProperty(Categories, key) ? Categories[key].params : null;
};

export const getKeys = () => {
  return Object.keys(Categories).map((key) => key);
};
