import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {FavoriteBookmark} from './styled';
import {postFavorite} from '../../../services/endpoints/contents/contents';

const Favorite = ({favorite, content}) => {
  const [state, setState] = useState({
    favorite,
  });

  const handleFavorite = async () => {
    try {
      await postFavorite({
        content,
      });

      setState({
        favorite: !favorite,
      });
    } catch (e) {
      alert('Ocorreu um erro ao favoritar o conteúdo');
    }
  };

  return (
    <FavoriteBookmark onClick={handleFavorite}>
      <i className={`fa${state.favorite ? 's' : 'r'} fa-bookmark`} />
    </FavoriteBookmark>
  );
};

Favorite.propTypes = {
  favorite: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
};

export {Favorite};
