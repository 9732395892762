import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import Script from 'react-load-script';
import {useHistory} from 'react-router-dom';
import toSlugCase from 'to-slug-case';
import {getSettings} from '../../services/endpoints/general/settings';
import {postContentView} from '../../services/endpoints/contents/contents';
import {getType} from '../Contents/Enum/types';

const SambaPlayer = ({reference, contentId, progressVideo, typeContent, nextVideo}) => {
  const [playerKey, setPlayerKey] = useState(null);
  const {location} = useHistory();
  let flag = false;
  const loadSettings = useCallback(async () => {
    try {
      const response = await getSettings();
      const data = response.data['hydra:member'] || [];
      setPlayerKey(data.filter((i) => i.name === 'sambavideos_playerKey')[0].value || null);
    } catch (e) {
      console.error('SambaPlayer.error', e);
    }
  }, []);

  const initSambaPlayer = () => {
    loadSettings();
  };

  const dispatchActionView = (time, duration) => {
    const percent = (time * 100) / duration;
    (async () => {
      try {
        await postContentView({
          content: contentId,
          percent: Math.floor(percent) || 100,
          progress: time,
        });
      } catch (e) {
        console.error('SambaPlayer.eventListener.onListen', e);
      }
    })();
  };

  const eventListener = (player) => {
    const eventParam = parseInt(player.eventParam, 10);
    switch (player.event) {
      case 'onLoad':
        break;
      case 'onStart':
        break;
      case 'onListen':
        if (!flag && eventParam > 1 && eventParam % 15 === 0) {
          dispatchActionView(eventParam, player.duration);
          flag = true;
        } else if (flag && eventParam > 1 && eventParam % 16 === 0) {
          flag = false;
        }
        break;
      case 'onPause':
        break;
      case 'onResume':
        break;
      case 'onSeek':
        break;
      case 'onCuepoint':
        break;
      case 'onMediaView':
        break;
      case 'onProgress':
        break;
      case 'onFinish':
        dispatchActionView(player.duration, player.duration);
        if (Object.keys(nextVideo).length) {
          setTimeout(() => {
            window.location.href = `/cursos/${toSlugCase(getType(nextVideo['@type']))}/${
              nextVideo.slug
            }${location.search}`;
          }, 1000);
        }
        break;
      case 'onError':
        break;
      default:
    }
  };

  if (playerKey) {
    (() =>
      new window.SambaPlayer('player', {
        ph: playerKey,
        m: reference,
        playerParams: {
          enabledShare: false,
          resume: progressVideo,
        },
        events: {
          '*': eventListener,
        },
      }))();
  }

  return (
    <>
      <Script
        url="https://player.sambatech.com.br/v3/samba.player.api.js"
        onLoad={initSambaPlayer}
      />
      <div
        className={`embed-responsive embed-responsive-${
          typeContent === 'Podcast' ? '10' : '16by9'
        } mb-3`}>
        <div className="embed-responsive-item" id="player" />
      </div>
    </>
  );
};

SambaPlayer.propTypes = {
  reference: PropTypes.string.isRequired,
  contentId: PropTypes.string.isRequired,
  progressVideo: PropTypes.number.isRequired,
  typeContent: PropTypes.string.isRequired,
  nextVideo: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
export default SambaPlayer;
