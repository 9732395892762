import styled from 'styled-components';
import {
  Item as ItemBase,
  LinkImage as LinkImageBase,
  Image as ImageBase,
  Title as TitleBase,
  Description as DescriptionBase,
  MetaInfo as MetaInfoBase,
} from '../../components/Contents/_partials/styled';

export const Item = styled(ItemBase)``;

export const LinkImage = styled(LinkImageBase).attrs({as: 'a'})``;

export const Image = styled(ImageBase)``;

export const Title = styled(TitleBase).attrs({as: 'a'})``;

export const Description = styled(DescriptionBase)``;

export const MetaInfo = styled(MetaInfoBase)``;
