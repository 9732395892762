import styled from 'styled-components';
import IconPlayer from './icons/play-button.svg';
import IconTrending from './icons/ceea_trending_up.png';
import IconPC from './icons/devices.png';
import IconNote from './icons/content.svg';

export const Box = styled.div`
  padding: 60px 0;
`;

export const Feature = styled.div`
  position: relative;
  text-align: center;
`;

export const FeatureBody = styled.div``;

export const Image = styled.img`
  max-width: 100%;
`;

export const Icon = styled.i`
  width: 72px;
  height: 72px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  display: block;
  margin: 0 auto 20px;

  &.icon-player {
    background-image: url(${IconPlayer});
  }
  &.icon-trending {
    background-image: url(${IconTrending});
  }
  &.icon-pc {
    background-image: url(${IconPC});
  }
  &.icon-note {
    background-image: url(${IconNote});
  }
`;

export const Title = styled.h3`
  margin: 0;
  padding: 0;
  color: #002c63;
  font-size: 15px;
`;
