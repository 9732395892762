import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Search = ({placeholder, type, onSubmit, setFieldValue, defaultValue}) => {
  return (
    <FormControl>
      <Input
        placeholder={placeholder || 'Digite aqui'}
        onChange={setFieldValue}
        defaultValue={defaultValue}
      />
      <Button type={type || 'button'} onClick={onSubmit}>
        <i className="fal fa-search" />
      </Button>
    </FormControl>
  );
};

const FormControl = styled.div`
  position: relative;
  margin-bottom: 30px;
`;

const Input = styled.input`
  font-family: Muli, sans-serif;
  border: 0;
  outline: none;
  border-bottom: 1px solid #00b0e6;
  background: #f7f7f7;
  font-size: 14px;
  padding: 8px 12px;
  display: block;
  width: 100%;
  height: 50px;
`;

const Button = styled.button`
  background: none;
  border: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  i {
    color: #002c63;
    font-size: 18px;
  }
`;

Search.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  defaultValue: PropTypes.string,
};

export {Search};
