import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-easy-crop';
import {getOrientation} from 'get-orientation/browser';
import Slider from '@material-ui/core/Slider';
import {
  Avatar,
  AvatarBox,
  AvatarContent,
  AvatarForm,
  AvatarHover,
  AvatarUploadFile,
  AvatarUploadText,
  ButtonUpload,
  CropperContent,
  CropperSlider,
  CropperText,
  Modal,
  ModalBody,
  ModalClose,
  ModalContent,
} from './styled';
import {getRotatedImage} from './util/getRotatedImage';
import getCroppedImg from './util/cropImage';
import noImage from './assets/noAvatar.jpg';
import {updateAvatar} from '../../services/endpoints/general/currentUser';

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
};

const AvatarUpload = ({user}) => {
  const {profile} = user;

  const [state, setState] = useState({
    image: null,
    crop: {x: 0, y: 0},
    zoom: 1,
    aspect: 1,
    rotation: 0,
    croppedAreaPixels: null,
    showModal: false,
    preview: null,
  });
  const fullname = profile.name;

  const handleModal = () => {
    setState((old) => ({
      ...old,
      image: null,
      crop: {x: 0, y: 0},
      zoom: 1,
      aspect: 1,
      rotation: 0,
      croppedAreaPixels: null,
      showModal: false,
      preview: null,
    }));
  };

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);
      const orientation = await getOrientation(file);
      const rotation = ORIENTATION_TO_ANGLE[orientation];

      if (rotation) {
        imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
      }

      setState((old) => ({
        ...old,
        image: imageDataUrl,
        showModal: true,
      }));
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setState((old) => ({
      ...old,
      croppedAreaPixels,
    }));
  }, []);

  const onCropChange = (crop) => {
    setState((old) => ({
      ...old,
      crop,
    }));
  };

  const onZoomChange = (zoom) => {
    setState((old) => ({
      ...old,
      zoom,
    }));
  };

  const onRotationChange = (rotation) => {
    setState((old) => ({
      ...old,
      rotation,
    }));
  };

  const uploadAvatarData = async (dataAvatar) => {
    try {
      const newFile = new File([dataAvatar], `${new Date().getTime()}.jpg`, {type: 'image/jpeg'});

      const data = new FormData();
      data.append('image', newFile);

      await updateAvatar(data);
    } catch (e) {
      console.error('Error uploadAvatarData:', e);
    }
  };

  const onSubmitAvatar = useCallback(async (dataAvatar) => {
    try {
      await uploadAvatarData(dataAvatar);
    } catch (e) {
      console.error('Error onSubmitAvatar', e);
    }
  }, []);

  const showImageCropped = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        state.image,
        state.croppedAreaPixels,
        state.rotation,
      );

      const urlPreview = URL.createObjectURL(croppedImage);

      await onSubmitAvatar(croppedImage);

      const newProfile = Object.assign(user, {
        ...user,
        profile: {
          ...user.profile,
          avatar: {
            ...user.profile.avatar,
            url: urlPreview,
          },
        },
      });
      localStorage.setItem(`${process.env.REACT_APP_PREFIX}.user`, JSON.stringify(newProfile));

      setState((old) => ({
        ...old,
        showModal: false,
        preview: urlPreview,
      }));
    } catch (e) {
      console.error(e);
    }
  }, [state, onSubmitAvatar, user]);

  const GetAvatar = () => {
    if (state.preview) {
      return <Avatar src={state.preview} alt={fullname} />;
    }
    if (profile.avatar && profile.avatar) {
      return <Avatar src={profile.avatar.url} alt={fullname} />;
    }

    return <Avatar src={noImage} alt={fullname} />;
  };

  return (
    <AvatarBox>
      <AvatarContent>
        <AvatarHover>
          <GetAvatar />

          <AvatarForm>
            <AvatarUploadText>Alterar Foto</AvatarUploadText>
            <AvatarUploadFile type="file" onChange={onFileChange} />
          </AvatarForm>
        </AvatarHover>
      </AvatarContent>

      {state.showModal === true && (
        <Modal>
          <ModalContent>
            <ModalClose onClick={() => handleModal()}>x</ModalClose>
            <ModalBody>
              {state.image && (
                <>
                  <CropperContent>
                    <Cropper
                      image={state.image}
                      crop={state.crop}
                      zoom={state.zoom}
                      aspect={state.aspect}
                      rotation={state.rotation}
                      onCropChange={onCropChange}
                      onZoomChange={onZoomChange}
                      onRotationChange={onRotationChange}
                      onCropComplete={onCropComplete}
                    />
                  </CropperContent>

                  <CropperSlider>
                    <CropperText>Zoom:</CropperText>
                    <Slider
                      value={state.zoom}
                      min={1}
                      max={5}
                      step={0.1}
                      aria-labelledby="Zoom"
                      onChange={(e, zoom) => setState((old) => ({...old, zoom}))}
                    />
                  </CropperSlider>

                  <CropperSlider>
                    <CropperText>Girar:</CropperText>
                    <Slider
                      value={state.rotation}
                      min={0}
                      max={360}
                      step={0.1}
                      aria-labelledby="Rotation"
                      onChange={(e, rotation) => setState((old) => ({...old, rotation}))}
                    />
                  </CropperSlider>

                  <ButtonUpload onClick={() => showImageCropped()}>Salvar imagem</ButtonUpload>
                </>
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </AvatarBox>
  );
};

AvatarUpload.propTypes = {
  user: PropTypes.object.isRequired,
};

export default AvatarUpload;
