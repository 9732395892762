import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Attachments = ({attachments}) => {
  const getUrl = (attachment) => {
    if (attachment.url) {
      return attachment.url;
    }
    if (attachment.registeredFile) {
      return attachment.registeredFile.url;
    }
    return null;
  };

  return (
    <AttachmentsStyled>
      <Title>Conteúdo complementar</Title>

      {attachments.map(
        (attachment) =>
          getUrl(attachment) && (
            <div key={attachment['@id']}>
              <Attachment
                href={getUrl(attachment)}
                download={getUrl(attachment)}
                target="_blank"
                rel="noopener noreferrer">
                <Icon className="far fa-cloud-download-alt" />
                <TitleAttachment>{attachment.title}</TitleAttachment>
              </Attachment>
            </div>
          ),
      )}
    </AttachmentsStyled>
  );
};

export const AttachmentsStyled = styled.div`
  border-top: 1px solid #e3e5e7;
  margin-top: 40px;
  padding-top: 10px;
`;

export const Title = styled.div`
  padding: 10px 0 10px 20px;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 10px;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  &:before {
    content: '';
    width: 14px;
    height: 2px;
    background: #0063de;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -1px;
  }
`;

export const Attachment = styled.a`
  font-weight: bold;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #0063de;
  text-decoration: none;
  display: inline-flex;
  padding: 10px 0;
  margin: 5px 0;
  align-items: center;
  transition: all 300ms ease;
  &:hover {
    color: #002c63;
  }
`;

export const Icon = styled.i`
  margin-right: 10px;
  font-size: 18px;
`;

export const TitleAttachment = styled.div``;

Attachments.propTypes = {
  attachments: PropTypes.array.isRequired,
};
export default Attachments;
