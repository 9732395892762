export const hasProperty = (source, param) => {
  if (!source) return false;
  return Object.prototype.hasOwnProperty.call(source, param);
};

export const priceFormat = (number) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(number);
};
