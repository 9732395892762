const Components = {};

Components.Menu = require('../Menu').default;
Components.Banner = require('../Banner').default;
Components.BannerSimple = require('../BannerSimple').default;
Components.Featuregroupsinfors = require('../FeatureGroupsInfors').default;
Components.Aboutblockhome = require('../About/AboutBlockHome').default;
Components.AboutBlockInternal = require('../About/AboutBlockInternal').default;
Components.Menufooter = require('../MenuFooter').default;
Components.Redessociais = require('../RedesSociais').default;
Components.Objectives = require('../Objectives').default;
Components.Partners = require('../Partners').default;
Components.BlockPartners = require('../Partners/Blocks/BlockPartners').default;
Components.Faq = require('../Faq').default;
Components.Contacts = require('../Contacts').default;
Components.BlockContentList = require('../Contents/Blocks/BlockContentList').default;
Components.BlockHubEducacao = require('../BlockHubEducacao').default;

export default Components;
