import React from 'react';
import PropTypes from 'prop-types';
import {Container} from 'reactstrap';
import * as Yup from 'yup';
import GenerateForm from '../GenerateForm';
import {ContactForm} from './styled';
import {sendContact} from '../../services/endpoints/cms/contact_messages';

const Contacts = ({content}) => {
  const groupFields = [
    {
      name: '',
      fields: [
        {
          label: 'Digite seu nome',
          hasPlaceholder: true,
          name: 'name',
          value: '',
          required: true,
          validations: {
            name: Yup.string().required('Campo obrigatório'),
          },
        },
        {
          label: 'Digite seu e-mail',
          hasPlaceholder: true,
          name: 'email',
          value: '',
          required: true,
          validations: {
            email: Yup.string()
              .required('Campo obrigatório')
              .email('Informe um email válido'),
          },
        },
        {
          label: 'Digite seu assunto',
          hasPlaceholder: true,
          name: 'subject',
          value: '',
          required: true,
          validations: {
            subject: Yup.string().required('Campo obrigatório'),
          },
        },
        {
          label: 'Digite sua mensagem',
          hasPlaceholder: true,
          name: 'message',
          value: '',
          required: true,
          validations: {
            message: Yup.string().required('Campo obrigatório'),
          },
          type: 'textarea',
        },
        /* {
          label: 'Aceitar a Newsletter',
          hasPlaceholder: false,
          name: 'newsletter',
          type: 'radio',
        }, */
      ],
    },
  ];
  const settings = {
    button: {
      text: 'Enviar mensagem',
      textSubmitting: 'Enviando...',
    },
  };

  const onSubmit = async (values, actions) => {
    try {
      await sendContact(content.slug, {
        ...values,
        newsletter: true,
      });

      const resetValues = {};
      Object.keys(values).forEach((key) => {
        Object.assign(resetValues, {[key]: ''});
      });

      actions.resetForm(resetValues);
    } catch (e) {
      alert('Erro ao enviar formulário ');
    }

    actions.setSubmitting(false);
  };

  return (
    <Container>
      <h1>{content.name}</h1>
      <ContactForm>
        <GenerateForm
          onSubmit={onSubmit}
          groupFields={groupFields}
          settings={settings}
        />
      </ContactForm>
    </Container>
  );
};

Contacts.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
export default Contacts;
