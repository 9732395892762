import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';
import {Button as ButtonEl} from '../../Elements';

export const Box = styled.div`
  position: relative;
  ${(props) =>
    props.showFavoriteRemove === true &&
    css`
      margin-top: 40px;
    `}
`;

export const ButtonRemoveFav = styled.div`
  font-size: 22px;
  color: #003475;
  position: absolute;
  top: -35px;
  right: 0;
  cursor: pointer;
`;

export const Item = styled.div`
  position: relative;
  margin-bottom: 20px;
  transition: all 400ms ease;
  height: 95.7%;
  background-color: #ffffff;
  box-shadow: 0 0 5px 0 #c7c5c5;
  &.no-shadow {
    box-shadow: none;
  }
  border-radius: 5px;
  &:hover {
    ${(props) =>
      props.noHover !== true &&
      css`
        transform: translate(0, -2px) scale(1.02);
      `}
  }
`;

export const LinkImage = styled.div.attrs((props) => ({
  as: props.type ? props.type : Link,
}))`
  position: relative;
  display: flex;
  padding-bottom: ${(props) => (props.widescreen ? '34.090909%' : '68.181818%')};
  overflow: hidden;
`;

export const Image = styled.img`
  width: 100%;
  position: absolute;
  object-fit: cover;
  object-position: center center;
  min-height: 100%;
  max-width: 100%;
`;

export const ImageResponsive = styled.img`
  width: 100%;
  max-width: 100%;
  margin-bottom: 15px;
`;

export const Description = styled.div`
  line-height: 20px;
  position: relative;
  ${({disableheight, height}) =>
    disableheight === 'true'
      ? css`
          max-height: ${height * 30}px;
        `
      : css`
          min-height: ${height * 30}px;
        `}
`;

export const Button = styled(ButtonEl).attrs((props) => ({
  type: props.type ? props.type : Link,
  color: props.color ? props.color : 'primary-light',
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

export const MetaInfo = styled.div`
  padding: 15px 10px 30px;
  ${Description} {
    white-space: nowrap;
  }
`;

export const TagsButtons = styled.div`
  display: flex;
  margin-bottom: 10px;
  height: 23px;
`;

const CategoryAndPartner = styled.div`
  padding: 3px 10px;
  border-radius: 20px;
  font-size: 10px;
  line-height: 17px;
  text-transform: uppercase;
  text-decoration: none;
  color: #002c63;
  margin-right: 5px;
  font-weight: bold;
`;

export const FreeContent = styled(CategoryAndPartner)`
  position: absolute;
  z-index: 9;
  top: 10px;
  left: 10px;
  background-color: #00985f;
  display: none;
`;

export const WatchedContent = styled(CategoryAndPartner)`
  position: absolute;
  z-index: 9;
  bottom: 10px;
  right: 10px;
  background-color: #00985f;
  color: #ffffff;
`;

export const Category = styled(CategoryAndPartner).attrs({as: Link})`
  background-color: #ffd862;
`;

export const Partner = styled(CategoryAndPartner).attrs({as: Link})`
  background-color: #e3e5e7;
`;

export const Title = styled.div.attrs(({type}) => ({
  as: type || Link,
}))`
  color: #40444d;
  text-decoration: none;
  font-size: 24px;
  line-height: 26px;
  display: block;
  margin-bottom: 15px;
  ${({disableheight, height}) =>
    disableheight === 'true'
      ? css`
          max-height: ${height * 30}px;
        `
      : css`
          min-height: ${height * 30}px;
        `}
`;

export const ContentTitle = styled.h1`
  font-size: 30px;
  line-height: 110%;
  margin-bottom: 30px;
  color: #004685;
  &:after {
    display: none;
  }
`;

export const RatingStarsInner = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
  width: ${(props) => (props.stars ? props.stars : 0)};
  &:before {
    font-weight: bold;
  }
`;

export const RatingStarsOuter = styled.div`
  display: inline-block;
  position: relative;
  font-family: 'Font Awesome 5 Pro', sans-serif;
  font-size: 15px;
  &:before,
  ${RatingStarsInner}:before {
    content: '\f005  \f005  \f005  \f005  \f005';
    color: #ffd862;
  }
`;

export const FavoriteBookmark = styled.div`
  font-size: 17px;
  color: #002c63;
  cursor: pointer;
`;

export const Embed = styled.div.attrs({
  className: 'embed-responsive embed-responsive-16by9 mb-3',
})`
  iframe,
  embed {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

export const Price = styled.div`
  margin: 10px 0;
  font-size: 20px;
  color: #f4a325;
  font-weight: bold;
  div {
    font-size: 14px;
    margin-bottom: 5px;
    text-decoration: line-through;
    color: #f4a325;
    font-weight: normal;
  }
`;
