import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {getGroupPartners} from '../../../services/endpoints/contents/contents';

const PartnersMenu = () => {
  const isMounted = useRef(true);
  const [loading, setLoading] = useState(true);
  const [contents, setContents] = useState([]);

  const loadContents = useCallback(async () => {
    const response = await getGroupPartners();
    const {data} = response;
    setContents(data['hydra:member'] || []);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      loadContents();
    }
    return () => {
      setLoading(true);
      isMounted.current = false;
    };
  }, [loadContents]);

  if (loading || !contents.length) {
    return null;
  }

  return (
    <li>
      <Link to="/cursos?by=parceiros">Parceiros</Link>
      <ul>
        {contents.map((content) => (
          <li key={content['@id']}>
            <Link to={`/parceiros/${btoa(content['@id'])}`}>{content.name}</Link>
          </li>
        ))}
      </ul>
    </li>
  );
};

export default PartnersMenu;
