import React from 'react';
import {Container} from 'reactstrap';
import Base from '../../components/Base';

const NotFound = () => {
  return (
    <Base slug="list-all-pages" hideBreadcrumb>
      <Container>
        <div className="text-center mt-5 mb-5">
          <h1 className="hide-border">Pagina não encontrada</h1>
          <p>Lamentamos, mas a página que você está procurando não existe.</p>
        </div>
      </Container>
    </Base>
  );
};

export default NotFound;
