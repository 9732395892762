import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Col, Row} from 'reactstrap';
import Playlist from '../_partials/Playlist';
import {getToken} from '../../../utils/auth';
import {hasProperty} from '../../../utils/functions';
import Attachments from '../../../components/Contents/_partials/Attachments';
import RelatedContents from '../_partials/RelatedContents';
import ContentType from './ContentType';
import {ContentLoggedOut} from '../../../components/Contents/Types/ContentLoggedOut';
import ShowBannerExam from '../_partials/ShowBannerExam';

const ContentShowByType = ({content, relatedContents, preferences, playlist}) => {
  if (!getToken() && !content.hasAccess) {
    return <ContentLoggedOut content={content} />;
  }

  return (
    <Row>
      <Col xs={12} md={9}>
        <ContentType content={content} playlist={playlist} watched={preferences.watched} />

        {getToken() && hasProperty(content, 'attachments') && !!content.attachments.length && (
          <Attachments attachments={content.attachments} />
        )}

        {!!Object.keys(preferences.exam).length && !preferences.examApproval && (
          <ShowBannerExam content={content} preferences={preferences} />
        )}

        {/* TODO implementar quando o conteúdo tem um certificado */}
        {preferences.hasCertificate &&
          (!preferences.mandatoryApproval || preferences.examApproval) && (
            <p>Emitir Certificado (Clique aqui para baixar)</p>
          )}
      </Col>
      <Col xs={12} md={3}>
        {!!playlist.length && (
          <Box style={{marginBottom: 90}}>
            <Title>Playlist</Title>
            <BoxScrollOuter>
              <BoxScrollInner>
                <Playlist content={content} playlist={playlist} />
              </BoxScrollInner>
            </BoxScrollOuter>
          </Box>
        )}

        {!!relatedContents.length && (
          <Box>
            <Title>Veja Também</Title>
            <RelatedContents contents={relatedContents} />
          </Box>
        )}
      </Col>
    </Row>
  );
};

const Box = styled.div`
  margin-bottom: 30px;
  position: relative;
`;

const BoxScrollOuter = styled.div`
  position: relative;
  overflow-y: scroll;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #002c63;
  }
  &::before {
    content: '';
    padding-bottom: 165.5%;
    display: block;
  }
`;

const BoxScrollInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 5px;
`;

const Title = styled.h2`
  font-weight: normal;
  font-size: 24px;
  line-height: 110%;
  color: #004685;
  margin-bottom: 25px;
`;

ContentShowByType.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  preferences: PropTypes.oneOfType([PropTypes.object]).isRequired,
  relatedContents: PropTypes.array.isRequired,
  playlist: PropTypes.array.isRequired,
};
export default ContentShowByType;
