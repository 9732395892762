import styled from 'styled-components';

export const Box = styled.div`
  display: flex;
  padding: 40px;
  border-radius: 3px;
  box-shadow: 1px 1px 15px 0 #a3a3a3;
`;

export const DashboardItem = styled.div`
  border: 1px solid rgba(0, 176, 230, 0.25);
  padding: 30px 10px;
  text-align: center;
  border-radius: 30px;
  width: 100%;
  height: 100%;
  transition: all 500ms ease;
  &:hover {
    border-color: transparent;
    box-shadow: 4px 4px 12px 0 rgba(0, 0, 0, 0.23);
  }
`;

export const DashboardIcon = styled.div`
  color: #002c63;
  font-size: 18px;
`;

export const DashboardTitle = styled.div`
  padding: 20px 0;
`;

export const DashboardValue = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

export const FormStyled = styled.div`
  .group-field {
    margin-bottom: 20px;

    > label {
      font-weight: bold;
      display: block;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
  }

  .element-field {
    margin-bottom: 15px;

    .label-title {
      font-weight: 300;
      display: block;
      margin-bottom: 5px;
    }

    .form-control:not([type='radio']) {
      font-family: Muli, sans-serif;
      border: 0;
      outline: none;
      border-bottom: 1px solid #00b0e6;
      background: #f7f7f7;
      font-size: 14px;
      padding: 8px 12px;
      display: block;
      width: 100%;
      &:not(textarea) {
        height: 38px;
      }
      &.form-textarea {
        height: 100px;
        border: 1px solid #00b0e6;
      }
    }

    span {
      color: rgba(255, 0, 0, 0.56);
      font-size: 12px;
    }
  }

  .footer-buttons {
    display: flex;
    justify-content: flex-end;
  }

  .btn {
    height: 46px;
    padding: 0 30px;
    border-radius: 30px;
    text-transform: uppercase;
    white-space: nowrap;
    background: #ffffff;
    border: 1px solid #0063de;
    font-weight: 600;
    margin: 0 7px;
    cursor: pointer;
    transition: all 0.3s linear;
    outline: none;
    text-decoration: none;
    color: #0063de;
    max-width: 220px;
    &:hover {
      background-color: rgba(0, 176, 230, 0.25);
      color: #0063de;
    }
  }
`;
