import React, {useCallback, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import momentPT from 'moment/locale/pt-br';
import toSlugCase from 'to-slug-case';
import {useHistory} from 'react-router-dom';
import {
  seeNotification,
  unseenNotifications,
} from '../../services/endpoints/notifications/notifications';
import {getType} from '../Contents/Enum/types';
import {Link} from '../Elements';

const BlockNotifications = () => {
  moment.updateLocale('pt-br', momentPT);
  const isMounted = useRef(true);
  const [contents, setContents] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const loadContent = useCallback(async () => {
    try {
      const response = await unseenNotifications();
      const {data} = response;
      setContents(data['hydra:member']);
    } catch (e) {
      console.error('BlockNotifications.loadContent', e);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      loadContent();
    }

    return () => {
      setLoading(true);
      isMounted.current = false;
    };
  }, [loadContent]);

  const handleClick = async (id, content) => {
    const url = `/cursos/${toSlugCase(getType(content['@type']))}/${content.slug}`;

    try {
      await seeNotification(id);
      history.push(url);
    } catch (e) {
      window.alert('Ocorreu um erro ao visualizar o conteúdo');
    }
  };

  if (loading) return null;

  return (
    <li>
      <Link to="/">
        <I count={contents.length || 0} className={`far fa-bell${contents.length ? ' has' : ''}`} />
      </Link>
      <Items>
        {contents.length ? (
          contents.map((content) => (
            <Item key={content['@id']} onClick={() => handleClick(content.id, content.content)}>
              <Title title={content.content.title}>{content.content.title}</Title>
              <Date>{moment(content.createdAt, 'YYYY MM DD').fromNow()}</Date>
            </Item>
          ))
        ) : (
          <li>
            <p>Você não tem notificações até o momento.</p>
          </li>
        )}
      </Items>
    </li>
  );
};

const Items = styled.ul`
  right: 0;
  p {
    padding: 10px;
    font-size: 15px;
    font-weight: 600;
    margin: 0;
  }
`;

const Item = styled.li`
  padding: 5px 10px;
  cursor: pointer;
  color: #003475;
  &:hover {
    background-color: #003475;
    color: #ffffff;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 14px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Date = styled.div`
  font-size: 12px;
`;
const I = styled.i`
  margin-right: 5px;
  position: relative;
  font-size: 14px;
  &.has:after {
    content: ${(props) => `'${props.count}'`};
    position: absolute;
    color: #002c63;
    width: 20px;
    height: 20px;
    background: #ffd862;
    border-radius: 50%;
    top: -15px;
    right: -7px;
    font-size: 11px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    font-family: Muli, sans-serif;
  }
`;

export default BlockNotifications;
