import {hasProperty} from '../../../utils/functions';

const Types = {
  Article: {
    title: 'Artigos',
    slug: 'artigos',
  },
  Ebook: {
    title: 'E-book',
    slug: 'e-book',
  },
  Infographic: {
    title: 'Inforgráficos',
    slug: 'inforgraficos',
  },
  Podcast: {
    title: 'Podcasts',
    slug: 'podcasts',
  },
  VideoConference: {
    title: 'Videocoferência',
    slug: 'videoconferencia',
  },
  Video: {
    title: 'Vídeos',
    slug: 'videos',
  },
  Webinar: {
    title: 'Webinar',
    slug: 'webinar',
  },
  Free: {
    title: 'Cursos Gratuitos',
    slug: 'gratuitos',
  },
  Saved: {
    title: 'Salvos',
    slug: 'salvos',
  },
  MyCourses: {
    title: 'Meus conteúdos comprados',
    slug: 'meus-cursos',
  },
};

export const getType = (type) => {
  return hasProperty(Types, type) ? Types[type].slug : '';
};

export const getTitleByType = (type) => {
  return hasProperty(Types, type) ? Types[type].title : '';
};

export const getTypeBySlug = (slug) => {
  let type = '';
  Object.keys(Types).forEach((key) => {
    if (hasProperty(Types, key) && Types[key].slug === slug) {
      type = key;
    }
  });

  return type;
};

export const getTypeTitle = (slug) => {
  let title = null;
  Object.keys(Types).forEach((key) => {
    if (hasProperty(Types, key) && (Types[key].slug === slug || key === slug)) {
      title = Types[key].title;
    }
  });
  return title;
};
