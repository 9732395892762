import React, {useCallback, useEffect, useState} from 'react';

const Certificates = () => {
  const [loading, setLoading] = useState(true);
  const [contents, setContents] = useState([]);

  const loadContents = useCallback(async () => {
    const response = {data: []}; // await _GET_API_;
    const {data} = response;
    setContents(data || []);
    setLoading(false);
  }, []);

  useEffect(() => {
    loadContents();
    return () => {
      setLoading(true);
    };
  }, [loadContents]);

  if (loading) {
    return null;
  }

  return (
    <div>
      {contents.length ? (
        contents.map((content) => <p key={content['@id']}>{content.title}</p>)
      ) : (
        <p>Sem conteúdos</p>
      )}
    </div>
  );
};

export default Certificates;
