import React, {useState} from 'react';
import Axios from 'axios';
import {Link} from 'react-router-dom';
import {
  Box,
  Button as ButtonSearch,
  Content,
  ContentLists,
  Input,
  Objetive,
  ObjetivesLists,
  ObjetiveTitle,
  Results,
} from './styled';
import {getSearchGlobal} from '../../../services/endpoints/general/search';
import {getType} from '../../Contents/Enum/types';
import {Button} from '../../Elements';

const {CancelToken} = Axios;
let cancel;

const SearchBlock = () => {
  const [showResults, setShowResults] = useState(false);
  const [contents, setContents] = useState([]);
  const [objetives, setObjetives] = useState([]);
  const [query, setQuery] = useState('');

  const autocompleteSearch = (search) => {
    if (cancel !== undefined) {
      cancel();
    }

    return getSearchGlobal(search, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
      }),
    })
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response));
  };

  const autoComplete = async (e) => {
    const {value} = e.target;
    setQuery(value);
    if (value.length > 3) {
      try {
        const results = await autocompleteSearch(value);
        setContents(results.contents.slice(0, 3) || []);
        setObjetives(results.trails.slice(0, 3) || []);
      } catch (error) {
        if (error !== undefined) console.error('SearchBlock.autoComplete', error);
      }
      setShowResults(true);
    } else {
      setContents([]);
      setObjetives([]);
      setShowResults(false);
    }
    return null;
  };

  const FormatContentTitle = (title) => {
    const strpos = title.indexOf(' ');
    if (strpos !== -1) {
      const thin = title.slice(0, strpos);
      return (
        <>
          {thin} <b>{title.slice(strpos)}</b>
        </>
      );
    }

    return title;
  };

  return (
    <Box>
      <Input type="text" placeholder="Qual assunto deseja buscar?" onChange={autoComplete} />
      <ButtonSearch type="button">
        <i className="fal fa-search" />
      </ButtonSearch>

      {showResults && (
        <Results>
          {!!contents.length && (
            <ContentLists hasBorder={!!objetives.length}>
              {contents.map((content) => (
                <Content
                  key={content['@id']}
                  to={`/cursos/${getType(content['@type'])}/${content.slug}`}
                  title={content.title}>
                  {FormatContentTitle(content.title)}
                </Content>
              ))}
            </ContentLists>
          )}

          {!!objetives.length && (
            <ObjetivesLists>
              <ObjetiveTitle>Busque por objetivos</ObjetiveTitle>
              {objetives.map((objetive) => (
                <Objetive
                  key={objetive['@id']}
                  to={`/cursos?by=objetivos&typeFilter=${btoa(objetive['@id'])}`}>
                  {objetive.title}
                </Objetive>
              ))}
            </ObjetivesLists>
          )}

          {!contents.length && !objetives.length ? (
            <p>Nenhum resultado encontrado</p>
          ) : (
            <Button className="mt-3" color="blue-to-white" type={Link} to={`/busca?query=${query}`}>
              Veja resultado completo
            </Button>
          )}
        </Results>
      )}
    </Box>
  );
};

export {SearchBlock};
