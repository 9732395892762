import React from 'react';
import PropTypes from 'prop-types';
import {Container} from 'reactstrap';
import {Description, Objective, Title} from './styled';

const Objectives = ({content}) => {
  const objetives = content['hydra:member'] || [];

  return (
    <Container>
      {objetives.map((objetive) => (
        <Objective key={objetive.id}>
          <Title>{objetive.title}</Title>
          <Description
            dangerouslySetInnerHTML={{__html: objetive.description}}
          />
        </Objective>
      ))}
    </Container>
  );
};

Objectives.propTypes = {
  content: PropTypes.object.isRequired,
};

export default Objectives;
